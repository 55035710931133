import { makeStyles } from "@material-ui/core";
import { Theme as DefaultTheme } from "@material-ui/core/styles/createMuiTheme";
import { Styles } from "@material-ui/styles";

export const addStyles = <
    Theme = DefaultTheme,
    Props extends object = {},
    ClassKey extends string = string
>(
    styles: Styles<Theme, Props, ClassKey>,
) => makeStyles(styles, { index: 1 });
