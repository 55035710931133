import { memo } from "react";
import { Draggable } from "react-beautiful-dnd";
import { areEqual } from "react-window";
import { DroppableRow } from "./DroppableRow";

interface iRow<T> {
    data: {
        items: T[];
        isDraggingDisabled: boolean;
    };
    index: number;
    style: React.CSSProperties;
}

export const DroppableRowWrapper = memo( <T extends { id: number }>(props: iRow<T>) => {
    const { data, index, style } = props;
    const item = data.items[index];

    return (
        <Draggable
            draggableId={`${item.id}`}
            index={index}
            key={item.id}
            isDragDisabled={data.isDraggingDisabled}
        >
            {provided => <DroppableRow provided={provided} index={index} style={style} />}
        </Draggable>
    );
}, areEqual);