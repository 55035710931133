import { useStyles } from "./ErrorMessage.styles";

interface iProps {
    shown: boolean;
}

const ErrorMessage: React.FC<iProps> = ({shown, children}) => {
    const classes = useStyles();

    if ( !shown ) return null;

    return <div className={classes.error}>{children}</div>
}

export default ErrorMessage;