import React, { useContext } from "react";
import { GlobalContext } from "../components/App/App";
import { iNotification, NotificationStatus } from "../components/NotificationsItem/NotificationsItem";

interface iNotificationAdd {
    message: React.ReactNode;
    status?: NotificationStatus;
    icon?: React.ReactNode;
}

export default function useNotification() {
    const { addNotify } = useContext(GlobalContext);

    const addNotification = (params: iNotificationAdd) => {
        const { message, status, icon } = params;
        const notify: iNotification = {
            message: message,
            status: status || "info",
            icon: icon || null
        };

        addNotify(notify);
    }

    return { addNotification };
}
