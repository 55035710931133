import clsx from "clsx";
import { memo } from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import { areEqual } from "react-window";
import MUITableRow from "../MUITable/MUITableRow";
import { getRowStyle } from "./helpers";

interface iDroppableRow {
    provided: DraggableProvided;
    style?: React.CSSProperties;
    isDragging?: boolean;
    index: number;
}

export const DroppableRow = memo(({ provided, style, index, isDragging = false }: iDroppableRow) => {
    const classNames = clsx("DroppableRow", isDragging && "is-dragging");

    return (
        <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            style={getRowStyle({ provided, style, isDragging })}
            className={classNames}
        >
            <MUITableRow index={index} style={{}} />
        </div>
    );
}, areEqual);