import { useState } from "react";
import { getApiLink } from "../apiConfig";
import { iTag__API } from "../apiSchema";
import API from "../enums/API";
import { iTagExtended, TagExtended } from "../models/Tag";
import { useData } from "../../hooks/useData";
import useFetch from "use-http";

export default function useTags () {
    const { get, post } = useFetch();
    async function getTags(): Promise<iTagExtended[]> {
        const response: iTag__API[] = await get(getApiLink(API.GET_TAGS));
        return response.map(tag => new TagExtended({ apiModel: tag }));
    }

    async function deleteTag(id: number): Promise<boolean> {
        return await post(getApiLink(API.DELETE_TAG, { id }));
    }

    const tags = useData(([] as iTagExtended[]), getTags);
    const [isLoading, setIsLoading] = useState(false);

    function removeTag (id: number) {
        setIsLoading(true);
        deleteTag(id).then(() => {
            setIsLoading(false);
            tags.reFetch();
        });
    }

    return {
        isLoading: tags.isLoading || isLoading,
        data: tags.data,
        removeTag,
    };
};