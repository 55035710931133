import { iMacSnapLight__API } from "../apiSchema";
import { transformDate } from "../helpers";
import dayjs from "dayjs";

export interface iMac {
    id: number;
    title: string;
    createDate: number;
    updateDate: number;
    bigImageName: string;
    hasImage: boolean;
}

export class Mac implements iMac {
    id = Mac.defaultData.id;
    title = Mac.defaultData.title;
    createDate = Mac.defaultData.createDate;
    updateDate = Mac.defaultData.updateDate;
    bigImageName = Mac.defaultData.bigImageName;
    hasImage = Mac.defaultData.hasImage;

    static defaultData: iMac = {
        id: 0,
        title: '',
        createDate: 0,
        updateDate: 0,
        bigImageName: '',
        hasImage: false,
    };

    constructor(data?: iMacSnapLight__API) {
        if (data) {
            this.mapFromApi(data);
        }
    }

     setData(model: iMac) {
        ({
            id: this.id,
            title: this.title,
            createDate: this.createDate,
            updateDate: this.updateDate,
            bigImageName: this.bigImageName,
            hasImage: this.hasImage,
        } = model);

        return this;
    }

    private mapFromApi(model: iMacSnapLight__API) {
        this.setData({
            id: model.id || Mac.defaultData.id,
            title: model.title || Mac.defaultData.title,
            createDate: model.createDate ? dayjs(model.createDate).valueOf() : Mac.defaultData.createDate,
            updateDate: model.updateDate ? dayjs(model.updateDate).valueOf() : Mac.defaultData.updateDate,
            bigImageName: model.bigImageName || Mac.defaultData.bigImageName,
            hasImage: !!model.bigImageName,
        });
    }

     mapForApi(): iMacSnapLight__API {
        return {
            id: this.id || Mac.defaultData.id,
            title: this.title || Mac.defaultData.title,
            createDate: this.createDate ? transformDate(this.createDate) : '',
            updateDate: this.updateDate ? transformDate(this.updateDate) : '',
            bigImageName: this.bigImageName || Mac.defaultData.bigImageName,
        };
    }
}