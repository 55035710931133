import { addStyles } from "../../core/addStyles";
import { STYLES } from "../../core/constants";


const useStyles = addStyles(theme => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        width: "600px",
        maxHeight: "90%",
        "&:focus": {
            outline: "none",
            border: "none",
        },
    },
    actions: {
        marginLeft: "auto",
        "& > * ": {
            marginLeft: theme.spacing(2),
        },
    },
    header: {
        height: 75,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: theme.spacing(0, 2),
        position: "relative",
        zIndex: 1
    },
    body: {
        padding: theme.spacing(2),
    },
    footer: {
        padding: theme.spacing(0, 2),
        position: "relative",
        zIndex: 1
    },
    submit: {
        minWidth: STYLES.MIN_WIDTH_BTN,
        height: "100%",
    },
}));

export { useStyles };
