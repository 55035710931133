import { FC, useContext } from "react";
import { MUITableRowWrapper } from "./index";
import { TableContext } from "./MUITable";
import MUITableCell from "./MUITableCell";

interface iProps {
    index: number;
    style: React.CSSProperties;
    isScrolling?: boolean;
}

const MUITableRow: FC<iProps> = (
    {
        index,
        style,
        isScrolling,
    }
) => {
    const { config } = useContext(TableContext);

    return (
        <MUITableRowWrapper index={index} style={style} isScrolling={isScrolling}>
            {config.map(({ Value, ...column }, colIndex) => (
                <MUITableCell
                    key={colIndex}
                    columnData={{ ...column, Value }}
                    props={{ head: true }}
                >
                    <Value index={index}/>
                </MUITableCell>
            ))}
        </MUITableRowWrapper>
    );
};

export default MUITableRow;