import { addStyles } from "../../core/addStyles";
import { STYLES } from "../../core/constants";

const useStyles = addStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    divider: {
        background: "black",
        opacity: "0.2",
        width: `calc(100% - ${theme.spacing(4)}px)`,
        margin: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    },
    dividerHidden: {
        opacity: "0",
    },
    row: {
        display: "flex",
        width: "100%",
    },
    actions: {
        marginLeft: "auto",
        "& > * ": {
            marginLeft: theme.spacing(2),
        },
    },
    dropzone: {
        padding: theme.spacing(2),
    },
    flexEnd: {
        display: "flex",
        justifyContent: "flex-end",
    },
    backward: {
        textDecoration: "none",
        height: "100%"
    },
    submit: {
        minWidth: STYLES.MIN_WIDTH_BTN,
        height: "100%"
    },
    preview: {
        maxWidth: "100%",
        flexBasis: "100%"
    },
    previewImg: {
        maxWidth: "100%",
        flexBasis: "100%"
    },
    previewContainer: {
        maxWidth: "100%",
        flexBasis: "100%"
    },
    textarea: {
        height: "100%"
    },
    relative: {
        position: "relative"
    },
    mb: {
        marginBottom: theme.spacing(1)
    },
    datepicker: {
        "& > .MuiFormHelperText-root": {
            display: "none"
        }
    },
    error: {
        color: theme.palette.error.main
    },
    fixHeight: {
        height: STYLES.INPUT_HEIGHT
    }
}));

export {useStyles};