import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import RoutesEnum from "../../core/enums/RoutesEnum";
import { Page, PageContent, PageHeader } from "../../components/PageLayout";
import HeaderActions from "../../components/HeaderActions/HeaderActions";
import useGetPublications from "../../core/api/useGetPublications";
import MUITable, { iTableConfigItem } from "../../components/MUITable/MUITable";
import ActionButtons from "../../components/ActionButtons/ActionButtons";
import DeleteIcon from "@material-ui/icons/Delete";
import PageSubHeader from "../../components/PageLayout/PageSubHeader";
import { FormInput } from "../../components/Form";
import { Grid } from "@material-ui/core";
import { iTagValidationData, tagValidationConfig } from "./Tag.helpers";
import { getInputErrors, isDataValid } from "../../core/formValidation";
import useNotification from "../../hooks/useNotifications";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import useGetRibbons from "../../core/api/useGetRibbons";
import SelectPublications from "../_Common/SelectPublications/SelectPublications";
import useTag from "../../core/api/useTag";
import useTags from "../../core/api/useTags";
import { equalInLC } from "../../core/helpers";

const Tag: FC = () => {
    const history = useHistory();
    const match = useRouteMatch<{ id: string | undefined }>();
    const id = useMemo(() => Number(match?.params?.id || 0), [match]);
    const { data, updateData, loading, save } = useTag(id);
    const { data: publications, loading: publicationsLoading } = useGetPublications();

    const { addNotification } = useNotification();
    const [isValidationShown, setIsValidationShown] = useState(false);
    const { data: tags } = useTags();


    const validationData: iTagValidationData = {
        title: data.title,
        uniqueTitle: !tags.some(i => i.id !== id && equalInLC(i.title, data.title)),
        publicationIds: data.publicationsIds,
    };

    const isValid: boolean = isDataValid(validationData)(tagValidationConfig);

    const selectedPublications = useMemo(
        () =>
            data.publicationsIds
                .filter(id => publications.some(i => i.id === id))
                .map(id => publications.find(i => i.id === id)!),
        [data.publicationsIds, publications],
    );

    const handleRemovePublication = useCallback(
        (id: number) => {
            updateData({
                publicationsIds: data.publicationsIds.filter(publicationId => publicationId !== id),
            });
        },
        [data, updateData],
    );

    const tableConfig: iTableConfigItem[] = useMemo(
        () => [
            { title: "ID", width: 70, Value: ({ index }) => <>{selectedPublications[index].id}</> },
            { title: "Title", Value: ({ index }) => <>{selectedPublications[index].title}</> },
            {
                title: "Date",
                width: 150,
                Value: ({ index }) => <>{selectedPublications[index].createDate}</>,
            },
            {
                title: "Category",
                Value: ({ index }) => <>{selectedPublications[index].titleCategoryName}</>,
            },
            {
                title: "Actions",
                width: 100,
                Value: ({ index }) => {
                    const item = selectedPublications[index];

                    return (
                        <ActionButtons
                            config={[
                                {
                                    ariaLabel: "Delete",
                                    Icon: DeleteIcon,
                                    color: "secondary",
                                    onClick: () => handleRemovePublication(item.id),
                                },
                            ]}
                        />
                    );
                },
            },
        ],
        [selectedPublications, handleRemovePublication],
    );

    const fieldHasError = (field: keyof iTagValidationData) =>
        !!getInputErrors(validationData)(tagValidationConfig)(field).length && isValidationShown;

    const fieldErrors = (field: keyof iTagValidationData) =>
        getInputErrors(validationData)(tagValidationConfig)(field).map((err, idx) => (
            <div key={idx}>{err}</div>
        ));

    const handleSave = async () => {
        if (isValid) {
            setIsValidationShown(false);

            save().then(() => {
                addNotification({
                    message: <span>Content Tag is saved.</span>,
                    status: "success",
                });
                history.push(RoutesEnum.Tags);
            });
        } else {
            setIsValidationShown(true);
            addNotification({
                message: <span>Please check validation errors</span>,
                status: "error",
            });
        }
    };



    return (
        <Page>
            <PageHeader title={`${match.params.id ? "Edit" : "Add"} Content Tag`}>
                <HeaderActions
                    hideSearch
                    submitText="Save"
                    onSubmit={handleSave}
                    backwardUrl={RoutesEnum.Tags}
                />
            </PageHeader>

            <PageContent>
                <PageSubHeader>
                    <Grid item xs={4}>
                        <FormInput
                            label="Title"
                            value={data.title}
                            onChange={value => updateData({ ...data, title: value })}
                            required
                            hasError={fieldHasError("title") || fieldHasError("uniqueTitle")}
                        >
                            <ErrorMessage shown={isValidationShown}>
                                {fieldErrors("title")}
                                {fieldErrors("uniqueTitle")}
                            </ErrorMessage>
                        </FormInput>
                    </Grid>
                    <Grid item xs={4}>
                        <SelectPublications
                            publications={publications}
                            publicationsIds={data.publicationsIds}
                            onChange={(publicationsIds) => {
                                updateData({
                                    ...data,
                                    publicationsIds,
                                });
                            }}
                            isDisabled={loading || publicationsLoading}
                        />
                    </Grid>
                </PageSubHeader>
                <MUITable
                    isLoading={loading}
                    config={tableConfig}
                    rowsCount={selectedPublications.length}
                    hasError={fieldHasError("publicationIds")}
                />
            </PageContent>
        </Page>
    );
};

export default Tag;
