import { Paper, Table, TableContainer } from "@material-ui/core";
import { useStyles } from "./MUITable.styles";

interface iProps {}

const MUITableWrapper: React.FC<iProps> = ({ children }) => {
    const tableClasses = useStyles();

    return (
        <div className={tableClasses.root}>
            <TableContainer component={Paper} className={tableClasses.tableContainer}>
                <Table className={tableClasses.table} component="div">
                    {children}
                </Table>
            </TableContainer>
        </div>
    );
};

export default MUITableWrapper;
