import useFetch from "use-http";
import { getApiLink } from "../apiConfig";
import API from "../enums/API";

const useUploadFile = () => {
    const { post, response, loading } = useFetch();

    const uploadFile = async (file: any) => {
        if (!file) return "";
        const currentFile = file;
        const filesData = new FormData();
        filesData.append("File", currentFile);
        return await post(getApiLink(API.UPLOAD_FILE), filesData);
    };

    return { uploadFile, response, loading }
}

export default useUploadFile;