import { addStyles } from "../../core/addStyles";
import { STYLES } from "../../core/constants";

const useStyles = addStyles(theme => ({
    formInput: {
        position: "relative",
        width: "100%"
    },
    formToggle: {
        position: "relative"
    },
    inputFixedHeight: {
        height: STYLES.INPUT_HEIGHT
    },
    textarea: {
        height: "100%",
    },
    datepicker: {
        "& > .MuiFormHelperText-root": {
            display: "none"
        }
    },
    highlighted: {
        background: theme.palette.warning.light,
    }
}));

export { useStyles };