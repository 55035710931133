import { useCallback, useEffect, useState } from "react";
import useFetch from "use-http";
import { getApiLink } from "../apiConfig";
import { iTag__API } from "../apiSchema";
import API from "../enums/API";
import { iTagExtended, TagExtended } from "../models/Tag";
import { useData } from "../../hooks/useData";

const useTag = (id: number | undefined) => {
    const { get, post, loading } = useFetch();
    const [data, setData] = useState<iTagExtended>(new TagExtended());

    const getTag = useCallback(async (): Promise<iTagExtended> => {
        const tag: iTag__API = await get(getApiLink(API.GET_TAG, { id }));

        return new TagExtended({ apiModel: tag });
    }, [get, id]);


    const tag = useData(new TagExtended(), getTag);

    const save = useCallback(async (): Promise<boolean> => {
        const response = await post(getApiLink(API.SAVE_TAG), {
            ...new TagExtended({ subModel: data }).exportForBackEnd(),
        });

        return !!response;
    }, [get, tag]);

    useEffect(() => {
        setData(tag.data);
    }, [tag.data]);

    const updateData = (updData: Partial<iTagExtended>): void => {
        setData(new TagExtended({ subModel: { ...data, ...updData }}));
    };

    return { data, updateData, loading, save };
}

export default useTag;