import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import RoutesEnum from "../../core/enums/RoutesEnum";
import { Page, PageContent, PageHeader } from "../../components/PageLayout";
import HeaderActions from "../../components/HeaderActions/HeaderActions";
import useGetRibbon from "../../core/api/useGetRibbon";
import useGetPublications from "../../core/api/useGetPublications";
import MUITable, { iTableConfigItem } from "../../components/MUITable/MUITable";
import ActionButtons from "../../components/ActionButtons/ActionButtons";
import DeleteIcon from "@material-ui/icons/Delete";
import PageSubHeader from "../../components/PageLayout/PageSubHeader";
import FormAutocomplete, { iAutocompleteOption } from "../../components/Form/FormAutocomplete";
import { FormInput } from "../../components/Form";
import { Grid } from "@material-ui/core";
import { iRibbonValidationData, ribbonValidationConfig } from "./Ribbon.helpers";
import { getInputErrors, isDataValid } from "../../core/formValidation";
import useNotification from "../../hooks/useNotifications";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import { getApiLink } from "../../core/apiConfig";
import API from "../../core/enums/API";
import useGetRibbons from "../../core/api/useGetRibbons";
import { useStyles } from "./Ribbon.styles";
import HighlightValue from "../../components/Form/HighlightValue";

const Ribbon: FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const match = useRouteMatch<{ id: string | undefined }>();
    const { data, updateData, loading, post, response } = useGetRibbon(Number(match?.params?.id));
    const { data: publications, loading: publicationsLoading } = useGetPublications();
    const [publicationsToOptions, setPublicationsToOptions] = useState<iAutocompleteOption[]>([]);
    const { addNotification } = useNotification();
    const [isValidationShown, setIsValidationShown] = useState(false);
    const ribbons = useGetRibbons();

    const validationData: iRibbonValidationData = {
        title: data.title,
        uniqueTitle: !ribbons.data
            .filter(item => item.id !== Number(match?.params?.id))
            .map(({ title }) => title.toLowerCase().trim())
            .includes(data.title.toLowerCase().trim()),
        publicationIds: data.publicationIds,
    };

    const isValid: boolean = isDataValid(validationData)(ribbonValidationConfig);

    const ribbonPublications = useMemo(
        () =>
            data.publicationIds
                .filter(id => publications.some(i => i.id === id))
                .map(id => publications.find(i => i.id === id)!),
        [data.publicationIds, publications],
    );

    const handleChangeAutocomplete = (selectedPublications: iAutocompleteOption[]) => {
        if (!selectedPublications.length) return;
        const { id } = selectedPublications[0];
        if (id) {
            updateData({
                ...data,
                publicationIds: [...data.publicationIds, id],
            });
        }
    };

    const handleRemovePublication = useCallback(
        (id: number) => {
            updateData({
                ...data,
                publicationIds: data.publicationIds.filter(publicationId => publicationId !== id),
            });
        },
        [data, updateData],
    );

    useEffect(() => {
        if (!publications.length) return;
        const filtered = publications.filter(item => !data.publicationIds.includes(item.id));
        setPublicationsToOptions(
            filtered.map(({ id, title, titleCategoryName, createDate }) => ({
                id,
                value: `${title} / ${titleCategoryName} / ${createDate}`,
            })),
        );
    }, [data.publicationIds, publications]);

    const tableConfig: iTableConfigItem[] = useMemo(
        () => [
            { title: "ID", width: 70, Value: ({ index }) => <>{ribbonPublications[index].id}</> },
            { title: "Title", Value: ({ index }) => <>{ribbonPublications[index].title}</> },
            {
                title: "Date",
                width: 150,
                Value: ({ index }) => <>{ribbonPublications[index].createDate}</>,
            },
            {
                title: "Category",
                Value: ({ index }) => <>{ribbonPublications[index].titleCategoryName}</>,
            },
            {
                title: "Actions",
                width: 100,
                Value: ({ index }) => {
                    const item = ribbonPublications[index];

                    return (
                        <ActionButtons
                            config={[
                                {
                                    ariaLabel: "Delete",
                                    Icon: DeleteIcon,
                                    color: "secondary",
                                    onClick: () => handleRemovePublication(item.id),
                                },
                            ]}
                        />
                    );
                },
            },
        ],
        [ribbonPublications, handleRemovePublication],
    );

    const fieldHasError = (field: keyof iRibbonValidationData) =>
        !!getInputErrors(validationData)(ribbonValidationConfig)(field).length && isValidationShown;

    const fieldErrors = (field: keyof iRibbonValidationData) =>
        getInputErrors(validationData)(ribbonValidationConfig)(field).map((err, idx) => (
            <div key={idx}>{err}</div>
        ));

    const handleSave = async () => {
        if (isValid) {
            setIsValidationShown(false);

            await post(getApiLink(API.SAVE_RIBBON), data.mapForApi());
            setIsValidationShown(false);

            if (response.ok) {
                addNotification({
                    message: <span>Content Ribbon is saved.</span>,
                    status: "success",
                });
                history.push(RoutesEnum.Ribbons);
            }
        } else {
            setIsValidationShown(true);
            addNotification({
                message: <span>Please check validation errors</span>,
                status: "error",
            });
        }
    };

    const customRenderOption = (option: iAutocompleteOption, value: string) => {
        const words = option.value.split(" / ");
        const title = words[0];
        const category = words[1];
        const date = words[2];
        const highlighTitle = HighlightValue({ option: { ...option, value: title}, inputValue: value });
        const highlightCategory = HighlightValue({ option: { ...option, value: category}, inputValue: value });
        return (
            <div className={classes.option}>
                <div className={classes.optionTitle}>{highlighTitle}</div>
                <div className={classes.optionInfo}>
                    {date && <span className={classes.optionDate}>{date}</span>}
                    {category && <span className={classes.optionCategory}>{highlightCategory}</span>}
                </div>
            </div>
        );
    };

    return (
        <Page>
            <PageHeader title={`${match.params.id ? "Edit" : "Add"} Content Ribbon`}>
                <HeaderActions
                    hideSearch
                    submitText="Save"
                    onSubmit={handleSave}
                    backwardUrl={RoutesEnum.Ribbons}
                />
            </PageHeader>

            <PageContent>
                <PageSubHeader>
                    <Grid item xs={4}>
                        <FormInput
                            label="Title"
                            value={data.title}
                            onChange={value => updateData({ ...data, title: value })}
                            required
                            hasError={fieldHasError("title") || fieldHasError("uniqueTitle")}
                        >
                            <ErrorMessage shown={isValidationShown}>
                                {fieldErrors("title")}
                                {fieldErrors("uniqueTitle")}
                            </ErrorMessage>
                        </FormInput>
                    </Grid>
                    <Grid item xs={4}>
                        <FormAutocomplete
                            label="Publications"
                            options={publicationsToOptions}
                            value={[]}
                            onChange={handleChangeAutocomplete}
                            isDisabled={loading || publicationsLoading}
                            blurOnSelect
                            renderOption={customRenderOption}
                            optionHeight={50}
                        />
                    </Grid>
                </PageSubHeader>
                <MUITable
                    isLoading={loading || publicationsLoading}
                    config={tableConfig}
                    rowsCount={ribbonPublications.length}
                    hasError={fieldHasError("publicationIds")}
                />
            </PageContent>
        </Page>
    );
};

export default Ribbon;
