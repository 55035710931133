import { FC } from "react";
import {
    Backdrop,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fade,
    Modal,
} from "@material-ui/core";
import { useStyles } from "./ManageOrders.styles";
import { MODAL_TOGGLE_TIME } from "../../../core/constants";

interface iProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: () => void;
    isLoading?: boolean;
}

export const ManageOrders: FC<iProps> = ({
    isOpen,
    onClose,
    onSubmit,
    isLoading = false,
    children,
}) => {
    const classes = useStyles();

    return (
        <Modal
            className={classes.modal}
            open={isOpen}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            disableBackdropClick
            BackdropProps={{
                timeout: MODAL_TOGGLE_TIME,
            }}
        >
            <Fade in={isOpen} unmountOnExit>
                <div className={classes.paper}>
                    <DialogTitle className={classes.header}>Reorder ribbons</DialogTitle>

                    <DialogContent className={classes.body}>
                        {children}
                    </DialogContent>

                    <div className={classes.footer}>
                        <DialogActions>
                            <Button onClick={onClose} color="default">
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                onClick={onSubmit}
                                color="primary"
                                autoFocus
                                disabled={isLoading}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};
