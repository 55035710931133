import { Typography } from "@material-ui/core";

interface iProps {
    title: string;
    isSub?: boolean;
}

const PageTitle: React.FC<iProps> = ({ title, isSub }) => {
    return (
        <Typography variant={isSub ? "h5" : "h4"} component={isSub ? "h3" : "h2"}>
            {title}
        </Typography>
    );
};

export default PageTitle;
