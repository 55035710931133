import { Container, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import RoutesEnum from "../../core/enums/RoutesEnum";
import Routes from "../../core/Routes";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import useStyles from "./App.styles";
import { Provider } from "use-http";
import NotificationsItem, { iNotification } from "../NotificationsItem/NotificationsItem";
import { Slide, toast, ToastContainer } from "react-toastify";
import './App.styles.scss';
import { apiOptions } from "../../core/apiConfig";
import useAdminTestFlight from "../../core/api/useAdminTestFlight";

interface iGlobalContextData {
    addNotify: (notify: iNotification, options?: any) => void;
}

class GlobalContextData implements iGlobalContextData {
    addNotify: (notify: iNotification, options?: any) => void = () => {
    };
}

export const GlobalContext: React.Context<GlobalContextData> = React.createContext(
    new GlobalContextData(),
);

export const addNotify = (notify: iNotification, options = {}) => {
    toast(<NotificationsItem notify={notify}/>, {
        ...options,
        onOpen: ({ toastProps }: any) => {
            const currentNotify = document.getElementById(toastProps.toastId);
            const currentHeight = currentNotify?.offsetHeight;
            currentNotify?.style.setProperty(
                "--offset",
                (currentHeight ? -currentHeight : 0) + "px",
            );
        },
    });
}


const App = () => {
    const classes = useStyles();
    const [showPanel, setShowPanel] = useState(true);
    const { hasAccess, isLoaded } = useAdminTestFlight();

    if (!isLoaded || !hasAccess) {
        return null;
    }

    return (
        <BrowserRouter>
            <Provider url={process.env.REACT_APP_API_LINK} options={apiOptions}>
                <GlobalContext.Provider
                    value={{
                        addNotify
                    }}
                >
                    <div className={classes.root}>
                        <Header showPanel={showPanel} togglePanel={setShowPanel}/>

                        <Sidebar showPanel={showPanel} togglePanel={setShowPanel}/>

                        <main className={classes.content}>
                            <div className={classes.appBarSpacer}/>
                            <Container maxWidth="xl" className={classes.container}>
                                <Grid container spacing={3}>
                                    <Switch>
                                        {Routes.map(route => (
                                            <Route key={route.path} {...route} />
                                        ))}
                                        <Route path="*">
                                            <Redirect to={RoutesEnum.Publications}/>
                                        </Route>
                                        <Redirect
                                            from={RoutesEnum.Home}
                                            to={RoutesEnum.Publications}
                                        />
                                    </Switch>
                                </Grid>
                            </Container>
                        </main>

                        <ToastContainer
                            position="top-right"
                            autoClose={5000}
                            hideProgressBar
                            closeOnClick
                            pauseOnFocusLoss
                            draggable={false}
                            pauseOnHover
                            transition={Slide}
                        />
                    </div>
                </GlobalContext.Provider>
            </Provider>
        </BrowserRouter>
    );
};

export default App;
