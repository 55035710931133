import { iFormConfigItem, isNotEmpty, isNotEmptyArray, isUnique } from "../../core/formValidation";

export interface iRibbonValidationData {
    title: string;
    publicationIds: number[];
    uniqueTitle: boolean;
}

export const ribbonValidationConfig: iFormConfigItem<iRibbonValidationData>[] = [
    { field: "title", validationCheckers: [isNotEmpty] },
    { field: "publicationIds", validationCheckers: [isNotEmptyArray] },
    { field: "uniqueTitle", validationCheckers: [isUnique] }
];