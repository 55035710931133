import { FC } from "react";
import useUploadFile from "../../core/api/useUploadFile";
import { iFile__API } from "../../core/apiSchema";
import { getBlankFile } from "../../pages/Publication/Publication.helpers";
import DropareaBase from "../Droparea/DropareaBase";

interface iProps {
    value: iFile__API | null;
    onRemove?: () => void;
    error?: boolean;
    types: string[];
    onLoadStart?: () => void;
    onChange?: (filename: string) => void;
    placeholder?: string;
}

const FormFileInput: FC<iProps> = ({
    onRemove = () => {},
    value = null,
    error = false,
    types,
    onLoadStart = () => {},
    onChange = () => {},
    placeholder,
}) => {
    const { uploadFile, response, loading } = useUploadFile();

    const handleAddFile = async (fileObjs: any[]) => {
        onLoadStart();
        const filename = await uploadFile(fileObjs[0].file);

        if (response.ok) {
            onChange(filename)
        }
    }

    const handleRemoveFile = () => {
        onRemove();
    };

    return (
        <DropareaBase
            acceptedFileTypes={types}
            onAdd={fileObjs => handleAddFile(fileObjs)}
            onDelete={handleRemoveFile}
            values={getBlankFile(value)}
            dropzoneText=""
            error={error}
            isLoading={loading}
            placeholder={placeholder}
        />
    );
};

export default FormFileInput;
