import { fade } from "@material-ui/core";
import { addStyles } from "../../core/addStyles";

const useStyles = addStyles(theme => ({
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: 200,
        height: "100%",
    },
    fixedOverlay: {
        position: "absolute",
        left: -theme.spacing(1),
        top: -theme.spacing(1),
        width: `calc(100% + ${theme.spacing(2)}px)`,
        height: `calc(100% + ${theme.spacing(2)}px)`,
        background: fade(theme.palette.background.default, 0.6),
        zIndex: theme.zIndex.appBar,
    },
    Spinner: {
        position: "fixed",
        left: "50%",
        top: "50%",
        width: "40px",
        height: "40px",
        marginTop: "40px",
        marginLeft: "-20px"
    },
    SpinnerCentered: {
        position: "absolute",
        left: "50%",
        top: "50%",
        width: "40px",
        height: "40px",
        marginTop: "-20px",
        marginLeft: "-20px"
    }
}));


export { useStyles };