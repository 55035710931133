import { FC, useCallback, useEffect } from "react";
import useUploadImage from "../../core/api/useUploadImage";
import { ImageType } from "../../core/apiSchema";
import useGetFile from "../../hooks/useGetFile";
import DropareaBase from "../Droparea/DropareaBase";

interface iProps {
    filename?: string;
    onChange?: (filename: string) => void;
    onLoadStart?: () => void;
    onRemove?: () => void;
    error?: boolean;
    uploadImageType?: ImageType;
    placeholder?: string;
}

const FormImageInput: FC<iProps> = ({
    onChange = () => {},
    onRemove = () => {},
    onLoadStart = () => {},
    filename,
    error = false,
    uploadImageType = ImageType.NUMBER_0,
    placeholder,
}) => {
    const {
        uploadImage,
        response: uploadImageResponse,
        loading: isImageUploading,
    } = useUploadImage();
    const {
        getImage,
        image,
        handleUpdateImage,
        isLoading: isImageLoading
    } = useGetFile();

    const handleUploadImage = useCallback(
        async (files: File[], imageType = uploadImageType) => {
            onLoadStart();
            const filename = await uploadImage(
                files.map((file: any) => file.file),
                imageType,
            );

            if (uploadImageResponse.ok) {
                onChange(filename);
            }
        },
        [onChange, uploadImage, uploadImageType, uploadImageResponse.ok, onLoadStart],
    );

    useEffect(() => {
        if (!filename) return;
        getImage(filename, uploadImageType);
    }, [filename, getImage, uploadImageType]);

    const handleRemoveImage = () => {
        handleUpdateImage([]);
        onRemove();
    };

    return (
        <DropareaBase
            acceptedFileTypes={["image/*"]}
            onAdd={handleUploadImage}
            onDelete={handleRemoveImage}
            values={image}
            dropzoneText="Drag and drop an image here or click"
            isLoading={isImageUploading || isImageLoading}
            error={error}
            placeholder={placeholder}
        />
    );
};

export default FormImageInput;
