import { addStyles } from "../../core/addStyles";

const useStyles = addStyles(theme => ({
    submit: {
        display: "flex",
        paddingLeft: theme.spacing(2),
        height: "100%",
        minWidth: 150,
        textDecoration: "none",
        marginLeft: theme.spacing(2)
    },
    backward: {
        textDecoration: "none",
        height: "100%",
        display: "flex",
        alignItems: "center"
    },
    actions: {
        display: "flex",

        "& > * ": {
            marginLeft: theme.spacing(2),
        },
    },
}));


export { useStyles };