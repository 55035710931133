import { useCallback, useEffect, useState } from "react";
import useFetch from "use-http";
import { getApiLink } from "../apiConfig";
import { iAdminRibbon__API } from "../apiSchema";
import API from "../enums/API";
import iRibbon, { Ribbon } from "../models/iRibbon";

const useGetRibbon = (id: number | undefined) => {
    const { get, response, post, abort, loading = true } = useFetch<iAdminRibbon__API>();
    const [data, setData] = useState<Ribbon>(new Ribbon());

    const getRibbon = useCallback(async () => {
        if (id) {
            const ribbon: iAdminRibbon__API = await get(getApiLink(API.GET_RIBBON, { id }));

            if (response.ok && ribbon) {
                setData(new Ribbon({ apiModel: ribbon }));
            }
        }
    }, [get, response, id]);

    useEffect(() => {
        getRibbon();
    }, [getRibbon]);

    useEffect(() => {
        return () => {
            abort();
        }
    }, [abort]);

    const updateData = (updData: Partial<iRibbon>): void => {
        setData(new Ribbon({ subModel: { ...data, ...updData }}));
    };

    return { data, updateData, loading, post, response }
}

export default useGetRibbon;