import { useContext } from "react";
import { TableContext } from "./MUITable";
import MUITableRowWrapper from "./MUITableRowWrapper";
import MUITableCell from "./MUITableCell";
import { iProps as iCellWrapperProps } from "./MUITableCellWrapper";

interface iProps {}

const MUITableHead: React.FC<iProps> = () => {
    const { config } = useContext(TableContext);

    return (
        <MUITableRowWrapper head>
            {config.map((column, index) =>  {
                const props: Partial<iCellWrapperProps> = { head: true };
                if (column.handleHeaderClick) {
                    props.onClick = column.handleHeaderClick;
                }

                return (
                    <MUITableCell
                        key={index}
                        columnData={column}
                        props={props}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                left: 0,
                                transform: `translateX(-100% - 3px)`,
                            }}
                        >
                            {column.icon}
                        </div>
                        {column.title}
                    </MUITableCell>
                );
            })}
        </MUITableRowWrapper>
    );
};

export default MUITableHead;
