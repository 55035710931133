import { DraggableProvided } from "react-beautiful-dnd";

export const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
}

interface iGetStyles {
    provided: DraggableProvided;
    style: any;
    isDragging: boolean;
}

export const getRowStyle = ({ provided, style, isDragging }: iGetStyles) => {
    const marginBottom = 0;

    const combined = {
        ...style,
        ...provided.draggableProps.style,
    };

    return {
        ...combined,
        height: isDragging ? combined.height : combined.height - marginBottom,
        marginBottom,
    };
}
