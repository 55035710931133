import { useCallback, useEffect, useState } from "react";
import useFetch from "use-http";
import { getApiLink } from "../apiConfig";
import API from "../enums/API";
import { iMac, Mac } from "../models/iMac";
import { iMacSnapLight__API } from "../apiSchema";

const useGetMacs = () => {
    const { get, response, post, abort, loading = true } = useFetch<iMacSnapLight__API[]>();
    const [data, setData] = useState<iMac[]>([]);

    const getAllMacs = useCallback(async () => {
        const macs: iMacSnapLight__API[] = await get(getApiLink(API.GET_ALL_MACS));

        if (macs) {
            setData(macs.map(mac => new Mac(mac)));
        }
    }, [get]);


    useEffect(() => {
        getAllMacs();
    }, [getAllMacs]);

    useEffect(() => {
        return () => {
            abort();
        }
    }, [abort]);

    return { data, loading, post, getAllMacs, response }
}

export default useGetMacs;