import { memo, ComponentProps, ComponentType } from "react";
import dayjs from "dayjs";

export const isEven = (n: number) => n % 2 === 0;

export function dynamicSort(property: string, sortingDirection: string) {
    const sortOrder = sortingDirection === "asc" ? 1 : -1;
    return (a: any, b: any) => {
        const itemA = a[property];
        const itemB = b[property];
        return (itemA < itemB ? -1 : itemA > itemB ? 1 : 0) * sortOrder;
    };
}

export const isValidDate = (date: any) => {
    return date instanceof Date && !isNaN(date.valueOf());
};

export const transformDate = (date: string | number) => {
    return dayjs(date).format('MM/DD/YYYY');
};

export function filterDataBySearch<T>(data: T[], searchQuery: string): T[] {
    const trimmedSearchQuery = searchQuery.trim();
    if (!trimmedSearchQuery) return data;


    return data.filter((item: any) => item.title.toLowerCase().includes(searchQuery.toLowerCase()));
};

export const filterArrayByIndex = <T>(arr: Array<T>, index: number): Array<T> => {
    return arr.filter((el, i) => index !== i);
};

export function getUnique<T>(arr: T[]): T[] {
    return Array.from(new Set(arr));
}


type PropsComparator<C extends ComponentType> = (
    prevProps: Readonly<ComponentProps<C>>,
    nextProps: Readonly<ComponentProps<C>>,
) => boolean;

export function typedMemo<C extends ComponentType<any>>(
    Component: C,
    propsComparator?: PropsComparator<C>,
) {
    return (memo(Component, propsComparator) as any) as C;
}

export const maxFileSizeInBytes = 1073741825;

export function prepareForSorting(value: any): string | number {
    switch (typeof value) {
        case "string": return value.toLowerCase();
        case "boolean": return value ? 1 : 0;
        default: return value;
    }
}

export function deepEqual<A, B>(a: A, b: B): boolean {
    return JSON.stringify(a) === JSON.stringify(b);
}

export function equalInLC(string1: string, string2: string) {
    return string1.toLowerCase().trim() === string2.toLowerCase().trim();
}