import { useEffect, useMemo, useState } from "react";
import ActionButtons, { ActionButtonTypeEnum } from "../../components/ActionButtons/ActionButtons";
import HeaderActions from "../../components/HeaderActions/HeaderActions";
import MUITable, { iTableConfigItem } from "../../components/MUITable/MUITable";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { highlightValue } from "../../components/MUITable/MUITable.helpers";
import { Page, PageContent, PageHeader } from "../../components/PageLayout";
import { filterDataBySearch, transformDate } from "../../core/helpers";
import RoutesEnum from "../../core/enums/RoutesEnum";
import useGetMacs from "../../core/api/useGetMacs";
import { iMac } from "../../core/models/iMac";
import useTableSort from "../../components/MUITable/useTableSort";
import { useTableConfig } from "./useTableConfig";

const Macs = () => {
    const { data, loading } = useGetMacs();
    const [searchQuery, setSearchQuery] = useState("");

    const filteredData: iMac[] = useMemo(
        () => filterDataBySearch(data, searchQuery),
        [data, searchQuery],
    );

    const {
        handleSort,
        sortingField,
        sortingDirection,
        getColumnIcon,
        setSortingDirection,
        sortedData,
    } = useTableSort(filteredData, "updateDate");

    const tableConfig = useTableConfig(
        sortedData,
        searchQuery,
        sortingField,
        sortingDirection,
        handleSort,
        getColumnIcon,
    );

    useEffect(() => {
        setSortingDirection("desc");
    }, []);

    return (
        <Page>
            <PageHeader title="MACs">
                <HeaderActions
                    searchQuery={searchQuery}
                    searchLabel="Search MACs"
                    setSearchQuery={(value: string) => setSearchQuery(value)}
                />
            </PageHeader>

            <PageContent>
                <MUITable isLoading={loading} config={tableConfig} rowsCount={sortedData.length}/>
            </PageContent>
        </Page>
    );
};

export default Macs;
