import { Divider, Grid, Typography } from "@material-ui/core";
import { useCallback, useMemo, useState } from "react";
import clsx from "clsx";
import RoutesEnum from "../../core/enums/RoutesEnum";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useStyles } from "./Publication.styles";
import { getApiLink } from "../../core/apiConfig";
import Loader from "../../components/Loader/Loader";
import useGetPublication from "../../core/api/useGetPublication";
import { formatValueToNumber, iValidationData, transformDataToBackend, validationConfig, } from "./Publication.helpers";
import API from "../../core/enums/API";
import { iPublication } from "../../core/models/iPublication";
import { getInputErrors, isDataValid } from "../../core/formValidation";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import useNotification from "../../hooks/useNotifications";
import FileTypes from "../../core/enums/FileTypes";
import { Page, PageContent, PageHeader } from "../../components/PageLayout";
import HeaderActions from "../../components/HeaderActions/HeaderActions";
import { FormDatepicker, FormInput, FormSelect, FormToggle } from "../../components/Form";
import useCategories from "../../core/api/useCategories";
import useGetSubscriptionPackages from "../../core/api/useGetSubscriptionPackages";
import { ImageType, iFile__API } from "../../core/apiSchema";
import FormImageInput from "../../components/Form/FormImageInput";
import FormFileInput from "../../components/Form/FormFileInput";
import { maxFileSizeInBytes } from "../../core/helpers";
import getFileSize from "../../core/getFileSize";
import useTagsShort from "../../core/api/useTagsShort";
import FormAutocomplete, { iAutocompleteOption } from "../../components/Form/FormAutocomplete";
import { iOptionValue } from "../../components/Form/Form.helpers";

const Publication = () => {
    const classes = useStyles();
    let history = useHistory();
    const match = useRouteMatch<{ id: string | undefined }>();
    const currentId = Number(match?.params?.id);
    const { values, setValues, response, loading, post } = useGetPublication(currentId);
    const { categoriesIsLoading, categories, subCategories } = useCategories();
    const subscriptionPackages = useGetSubscriptionPackages();
    const tags = useTagsShort();
    const [isValidationShown, setIsValidationShown] = useState(false);
    const [datepickerNativeErrors, setDatepickerNativeErrors] = useState<any>(null);
    const [isFileUploading, setIsFileUploading] = useState(false);
    const getAttachedFiles = (values: iPublication): iFile__API[] => {
        const { filePDF, fileSamplePDF, fileOperatorIdeaXLS, fileConsumerIdeaXLS, filePPT } = values;

        const attachedFiles: iFile__API[] = [];
        if ( filePDF ) attachedFiles.push(filePDF);
        if ( fileSamplePDF ) attachedFiles.push(fileSamplePDF);
        if ( fileOperatorIdeaXLS ) attachedFiles.push(fileOperatorIdeaXLS);
        if ( fileConsumerIdeaXLS ) attachedFiles.push(fileConsumerIdeaXLS);
        if ( filePPT ) attachedFiles.push(filePPT);

        return attachedFiles;
    }
    const validationData: iValidationData = {
        title: values.title,
        bigImageName: values.bigImageName,
        numberOfPages: values.numberOfPages,
        minutesToRead: values.minutesToRead,
        price: values.price || 0,
        createDate: values.createDate,
        categoryId: values.buttonCategoryId,
        subCategoryId: values.titleCategoryId,
        attachedFiles: getAttachedFiles(values)
    };
    const isValid: boolean = isDataValid(validationData)(validationConfig);
    const { addNotification } = useNotification();

    const handleSave = async () => {
        const transformDataToBack = transformDataToBackend(values);

        if (isValid) {
            await post(getApiLink(API.SAVE_PUBLICATION), transformDataToBack);
            setIsValidationShown(false);
            if (response.ok) {
                addNotification({
                    message: <span>Publication saved</span>,
                    status: "success",
                });
                history.push(RoutesEnum.Publications);
            } else {
                addNotification({
                    message: <span>Something went wrong</span>,
                    status: "error",
                });
            }
        } else {
            setIsValidationShown(true);
            addNotification({
                message: <span>Please check validation errors</span>,
                status: "error",
            });
        }
    };

    const handleChangeField = (prop: keyof iPublication) => (value: any) => {
        setValues(prevState => ({ ...prevState, [prop]: value }));
    };

    const subCategorySelectValue = useMemo(() => {
        return !categoriesIsLoading && values.titleCategoryId && subCategories.length
            ? values.titleCategoryId
            : "";
    }, [categoriesIsLoading, values.titleCategoryId, subCategories.length]);

    const categorySelectValue = useMemo(
        () => (!categoriesIsLoading && values.buttonCategoryId ? values.buttonCategoryId : ""),
        [categoriesIsLoading, values.buttonCategoryId],
    );

    const fieldHasError = (field: keyof iValidationData) =>
        !!getInputErrors(validationData)(validationConfig)(field).length && isValidationShown;

    const fieldErrors = (field: keyof iValidationData) =>
        getInputErrors(validationData)(validationConfig)(field).map((err, idx) => (
            <div key={idx}>{err}</div>
        ));

    const handleChangeFile = ({ filename, type, field }: { filename: string; type: FileTypes; field: keyof iPublication; }) => {
        handleChangeField(field)({ filename, type });
        setIsFileUploading(false);
    }

    const handleChangeTags = useCallback((value: iAutocompleteOption[]) => {
        handleChangeField("tagsIds")([...value.map(option => option.id)]);
    }, []);

    const tagsOptions: iAutocompleteOption[] = useMemo(() => {
        return tags.data.filter(tag => !values.tagsIds.includes(tag.id)).map(tag => ({
            id: tag.id,
            value: tag.title,
        }));
    }, [tags.data, values.tagsIds]);

    const tagsSelectedOptions: iOptionValue[] = useMemo(() => {
        return tags.data.filter(tag => values.tagsIds.includes(tag.id)).map(tag => ({
            id: tag.id,
            value: tag.title,
        }));
    }, [tags.data, values.tagsIds]);

    const maxFileSize = getFileSize(maxFileSizeInBytes);

    return (
        <Page>
            <PageHeader title={`${currentId ? "Edit" : "Add"} publication`}>
                <HeaderActions
                    hideSearch
                    submitText="Save"
                    submitIsDisabled={loading || categoriesIsLoading || isFileUploading}
                    onSubmit={handleSave}
                    backwardUrl={RoutesEnum.Publications}
                />
            </PageHeader>

            <PageContent>
                <Loader shown={loading || categoriesIsLoading} fixedOverlay/>

                {/* Form */}
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <FormInput
                            label="Title"
                            value={values.title}
                            onChange={value => handleChangeField("title")(value)}
                            hasError={fieldHasError("title")}
                            required
                        >
                            <ErrorMessage shown={isValidationShown}>
                                {fieldErrors("title")}
                            </ErrorMessage>
                        </FormInput>
                    </Grid>

                    <Grid item xs={6} className={classes.flexEnd}>
                        <FormToggle
                            label="Published"
                            onChange={value => handleChangeField("isPublished")(value)}
                            checked={values.isPublished}
                        />
                    </Grid>

                    <Divider className={classes.divider}/>

                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <FormInput
                                    label="Description"
                                    value={values.description}
                                    onChange={value => handleChangeField("description")(value)}
                                    textarea
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <FormInput
                                            label="Number of pages"
                                            value={values.numberOfPages ?? ""}
                                            onChange={value => handleChangeField("numberOfPages")(formatValueToNumber(value))}
                                            hasError={fieldHasError("numberOfPages")}
                                        >
                                            <ErrorMessage shown={isValidationShown}>
                                                {fieldErrors("numberOfPages")}
                                            </ErrorMessage>
                                        </FormInput>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormInput
                                            label="Price"
                                            value={values.price ?? ""}
                                            onChange={value => handleChangeField("price")(formatValueToNumber(value))}
                                            adornment="$"
                                            hasError={fieldHasError("price")}
                                        >
                                            <ErrorMessage shown={isValidationShown}>
                                                {fieldErrors("price")}
                                            </ErrorMessage>
                                        </FormInput>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormDatepicker
                                            label="Publication date"
                                            value={values.createDate}
                                            required
                                            onChange={value =>
                                                handleChangeField("createDate")(value)
                                            }
                                            hasError={
                                                fieldHasError("createDate") ||
                                                (!!datepickerNativeErrors && isValidationShown)
                                            }
                                            onError={error => setDatepickerNativeErrors(error)}
                                        >
                                            <ErrorMessage shown={isValidationShown}>
                                                {fieldErrors("createDate")}
                                                <div>{datepickerNativeErrors}</div>
                                            </ErrorMessage>
                                        </FormDatepicker>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormInput
                                            label="Minutes to read"
                                            value={values.minutesToRead ?? ""}
                                            onChange={value =>
                                                handleChangeField("minutesToRead")(formatValueToNumber(value))
                                            }
                                            hasError={fieldHasError("minutesToRead")}
                                        >
                                            <ErrorMessage shown={isValidationShown}>
                                                {fieldErrors("minutesToRead")}
                                            </ErrorMessage>
                                        </FormInput>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider className={classes.divider}/>

                    <Grid item xs={3}>
                        <FormSelect
                            label="Browse Page Filter Button"
                            value={categorySelectValue}
                            onChange={value => handleChangeField("buttonCategoryId")(value)}
                            isDisabled={categoriesIsLoading}
                            options={categories}
                            hasError={fieldHasError("categoryId")}
                            required
                        >
                            <ErrorMessage shown={isValidationShown}>
                                {fieldErrors("categoryId")}
                            </ErrorMessage>
                        </FormSelect>
                    </Grid>
                    <Grid item xs={3}>
                        <FormSelect
                            label="Browse by Title"
                            value={subCategorySelectValue}
                            onChange={value => handleChangeField("titleCategoryId")(value)}
                            isDisabled={categoriesIsLoading || !subCategories.length}
                            options={subCategories}
                            hasError={fieldHasError("subCategoryId")}
                            required
                        >
                            <ErrorMessage shown={isValidationShown}>
                                {fieldErrors("subCategoryId")}
                            </ErrorMessage>
                        </FormSelect>
                    </Grid>
                    <Grid item xs={6}>
                        <FormSelect
                            label="Subscription packages"
                            value={values.subscriptionPackageIds}
                            onChange={value => handleChangeField("subscriptionPackageIds")(value)}
                            isDisabled={false}
                            options={subscriptionPackages.data}
                            isLoading={subscriptionPackages.loading}
                        />
                    </Grid>

                    <Divider className={classes.divider} />
                    <Grid item xs={12}>
                        <FormAutocomplete
                            label="Content Tags (20 max)"
                            options={tagsOptions}
                            value={tagsSelectedOptions}
                            isDisabled={loading}
                            onChange={handleChangeTags}
                        />
                    </Grid>

                    <Divider className={classes.divider}/>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Grid container spacing={2}>
                                    <Grid item xs>
                                        <Typography
                                            variant="subtitle2"
                                            component="h3"
                                            className={clsx(
                                                classes.mb,
                                                fieldHasError("bigImageName") && classes.error,
                                            )}
                                        >
                                            Cover Image/Thumbnail*
                                        </Typography>

                                        <FormImageInput
                                            filename={values.bigImageName}
                                            onChange={filename => handleChangeField("bigImageName")(filename)}
                                            onRemove={() => handleChangeField("bigImageName")("")}
                                            error={fieldHasError("bigImageName")}
                                            placeholder="Optimal image size — 670x890"
                                        />
                                        <ErrorMessage shown={isValidationShown}>
                                            {fieldErrors("bigImageName")}
                                        </ErrorMessage>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={8}>
                                <Grid container spacing={2}>
                                    <Grid item xs>
                                        <Typography
                                            variant="subtitle2"
                                            component="h3"
                                            className={clsx(classes.mb)}
                                        >
                                            Featured Content Image
                                        </Typography>

                                        <FormImageInput
                                            filename={values.featuredContentImageName || ""}
                                            uploadImageType={ImageType.NUMBER_2}
                                            onChange={filename => handleChangeField("featuredContentImageName")(filename)}
                                            onRemove={() => handleChangeField("featuredContentImageName")("")}
                                            placeholder="Optimal image size — 1280x760"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider className={clsx(classes.divider, classes.dividerHidden)}/>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs>
                            <Typography
                                variant="subtitle2"
                                component="h3"
                                className={clsx(classes.mb, fieldHasError("attachedFiles") && classes.error)}
                            >
                                PDF
                            </Typography>

                            <FormFileInput
                                types={["application/pdf"]}
                                value={values.filePDF}
                                onRemove={() => handleChangeField("filePDF")(null)}
                                error={fieldHasError("attachedFiles")}
                                onLoadStart={() => setIsFileUploading(true)}
                                onChange={(filename) =>
                                    handleChangeFile({
                                        field: "filePDF",
                                        filename,
                                        type: FileTypes.Pdf,
                                    })
                                }
                                placeholder={`Max file size — ${maxFileSize.classicIntFormat}`}
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography
                                variant="subtitle2"
                                component="h3"
                                className={clsx(classes.mb, fieldHasError("attachedFiles") && classes.error)}
                            >
                                PDF Sample
                            </Typography>

                            <FormFileInput
                                types={["application/pdf"]}
                                value={values.fileSamplePDF}
                                onRemove={() => handleChangeField("fileSamplePDF")(null)}
                                error={fieldHasError("attachedFiles")}
                                onLoadStart={() => setIsFileUploading(true)}
                                onChange={(filename) =>
                                    handleChangeFile({
                                        field: "fileSamplePDF",
                                        filename,
                                        type: FileTypes.SamplePdf,
                                    })
                                }
                                placeholder={`Max file size — ${maxFileSize.classicIntFormat}`}
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography
                                variant="subtitle2"
                                component="h3"
                                className={clsx(classes.mb, fieldHasError("attachedFiles") && classes.error)}
                            >
                                PPT
                            </Typography>

                            <FormFileInput
                                types={[".ppt", ".pptx"]}
                                value={values.filePPT}
                                onRemove={() => handleChangeField("filePPT")(null)}
                                error={fieldHasError("attachedFiles")}
                                onLoadStart={() => setIsFileUploading(true)}
                                onChange={(filename) =>
                                    handleChangeFile({
                                        field: "filePPT",
                                        filename,
                                        type: FileTypes.Ppt,
                                    })
                                }
                                placeholder={`Max file size — ${maxFileSize.classicIntFormat}`}
                            />
                        </Grid>

                        <Grid item xs>
                            <Typography
                                variant="subtitle2"
                                component="h3"
                                className={clsx(classes.mb, fieldHasError("attachedFiles") && classes.error)}
                            >
                                XLS Operator
                            </Typography>

                            <FormFileInput
                                types={[".xlsx", ".xls"]}
                                value={values.fileOperatorIdeaXLS}
                                onRemove={() => handleChangeField("fileOperatorIdeaXLS")(null)}
                                error={fieldHasError("attachedFiles")}
                                onLoadStart={() => setIsFileUploading(true)}
                                onChange={(filename) =>
                                    handleChangeFile({
                                        field: "fileOperatorIdeaXLS",
                                        filename,
                                        type: FileTypes.XlsOperatorIdeaTool,
                                    })
                                }
                                placeholder={`Max file size — ${maxFileSize.classicIntFormat}`}
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography
                                variant="subtitle2"
                                component="h3"
                                className={clsx(classes.mb, fieldHasError("attachedFiles") && classes.error)}
                            >
                                XLS Consumer
                            </Typography>

                            <FormFileInput
                                types={[".xlsx", ".xls"]}
                                value={values.fileConsumerIdeaXLS}
                                onRemove={() => handleChangeField("fileConsumerIdeaXLS")(null)}
                                error={fieldHasError("attachedFiles")}
                                onLoadStart={() => setIsFileUploading(true)}
                                onChange={(filename) =>
                                    handleChangeFile({
                                        field: "fileConsumerIdeaXLS",
                                        filename,
                                        type: FileTypes.XlsConsumerIdeaTool,
                                    })
                                }
                                placeholder={`Max file size — ${maxFileSize.classicIntFormat}`}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <br/>
                        <ErrorMessage shown={isValidationShown}>
                            {fieldErrors("attachedFiles")}
                        </ErrorMessage>
                        <br/>
                    </Grid>
                </Grid>
            </PageContent>
        </Page>
    );
};

export default Publication;
