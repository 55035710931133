import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { addStyles } from "../../core/addStyles";

interface iProps {
    message?: string;
    hasError?: boolean;
}
const NoDataMessage = ({ hasError, message = "No data" }: iProps) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.message, hasError && classes.error)}>
            <Typography variant="h5" component="h2">
                {message}
            </Typography>
        </div>
    );
};

const useStyles = addStyles(theme => ({
    message: {
        minWidth: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(4)
    },
    error: {
        color: theme.palette.error.main
    }
}));

export default NoDataMessage;
