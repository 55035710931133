import { useState, useEffect } from "react";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import CustomScrollbarsVirtualList from "../CustomScrollbarsVirtualList/CustomScrollbarsVirtualList";
import { reorder } from "./helpers";
import { iTableConfigItem, iTableProps, TableContext } from "../MUITable/MUITable";
import { MUITableBodyWrapper, MUITableHeadWrapper, MUITableWrapper } from "../MUITable";
import MUITableHead from "../MUITable/MUITableHead";
import { TABLE_ROW_SIZE } from "../../core/constants";
import { DroppableRow } from "./DroppableRow";
import { DroppableRowWrapper } from "./DroppableRowWrapper";
import { typedMemo } from "../../core/helpers";
import "./SortableTable.styles.scss";

interface iProps<T> extends Omit<iTableProps, "config"> {
    id: string;
    isDraggingDisabled?: boolean;
    onDragStart?: () => void;
    onDragEnd?: (data: T[]) => void;
    data: T[];
    config: (data: T[]) => iTableConfigItem[]
}

const SortableTable = <T extends { id: number }>(props: iProps<T>) => {
    const {
        isDraggingDisabled = false,
        onDragEnd = () => {},
        onDragStart = () => {},
        data = [],
        id,
        ...tableProps
    } = props;
    const [items, setItems] = useState<T[]>(data);

    useEffect(() => {
        setItems(data);
    }, [data]);

    const handleDragStart = () => onDragStart();

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) return;
        if (result.source.index === result.destination.index) return;

        const reorderedItems: T[] = reorder(items, result.source.index, result.destination.index);
        setItems(reorderedItems);
        onDragEnd(reorderedItems);
    }

    return (
        <DragDropContext
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
        >
            <TableContext.Provider value={{ config: tableProps.config(items) }}>
                <MUITableWrapper>
                    <MUITableHeadWrapper>
                        <MUITableHead/>
                    </MUITableHeadWrapper>

                    <div className="SortableTable">
                        <MUITableBodyWrapper
                            hasError={tableProps.hasError}
                            isLoading={tableProps.isLoading}
                            rowsCount={tableProps.rowsCount}
                        >
                            <Droppable
                                droppableId={id}
                                mode="virtual"
                                isDropDisabled={isDraggingDisabled}
                                renderClone={(provided, snapshot, rubric) => (
                                    <DroppableRow
                                        provided={provided}
                                        isDragging={snapshot.isDragging}
                                        index={rubric.source.index}
                                    />
                                )}
                            >
                                {provided => {
                                    return (
                                        <AutoSizer
                                            defaultWidth={1}
                                            defaultHeight={1}
                                        >
                                            {({ width, height }) => (
                                                <List
                                                    height={height}
                                                    width={width}
                                                    itemCount={items.length}
                                                    itemSize={tableProps.rowHeight || TABLE_ROW_SIZE}
                                                    outerRef={provided.innerRef}
                                                    outerElementType={CustomScrollbarsVirtualList}
                                                    itemData={{
                                                        items,
                                                        isDraggingDisabled
                                                    }}
                                                >
                                                    {DroppableRowWrapper}
                                                </List>
                                            )}
                                        </AutoSizer>
                                    );
                                }}
                            </Droppable>
                        </MUITableBodyWrapper>
                    </div>
                </MUITableWrapper>
            </TableContext.Provider>
        </DragDropContext>
    );
};




export default typedMemo(SortableTable);