import PageChapters from "../pages/Chapters/Chapters";
import Publication from "../pages/Publication/Publication";
import Publications from "../pages/Publications/Publications";
import RoutesEnum from "./enums/RoutesEnum";
import Ribbons from "../pages/Ribbons/Ribbons";
import Ribbon from "../pages/Ribbon/Ribbon";
import FeaturedContent from "../pages/FeaturedContent/FeaturedContent";
import Macs from "../pages/Macs/Macs";
import Mac from "../pages/Macs/Mac/Mac";
import { Tags } from "../pages/ContentTags/Tags";
import Tag from "../pages/Tag/Tag";

export interface iRoute {
    path: RoutesEnum;
    title: string;
    exact?: boolean;
    component: React.ComponentType<any>;
}

const Routes: iRoute[] = [
    {
        path: RoutesEnum.Publications,
        exact: true,
        title: "Publications",
        component: Publications,
    },
    {
        path: RoutesEnum.PublicationEdit,
        exact: false,
        title: "Publication Edit",
        component: Publication,
    },
    {
        path: RoutesEnum.PublicationAdd,
        exact: false,
        title: "Publication Add",
        component: Publication,
    },
    {
        path: RoutesEnum.Chapters,
        exact: true,
        title: "Chapters",
        component: PageChapters,
    },
    {
        path: RoutesEnum.Ribbons,
        exact: true,
        title: "Content Ribbons",
        component: Ribbons,
    },
    {
        path: RoutesEnum.RibbonAdd,
        exact: true,
        title: "Add Content Ribbon",
        component: Ribbon,
    },
    {
        path: RoutesEnum.RibbonEdit,
        exact: false,
        title: "Edit Content Ribbon",
        component: Ribbon,
    },
    {
        path: RoutesEnum.FeaturedContent,
        exact: true,
        title: "Manage Featured Content List",
        component: FeaturedContent,
    },
    {
        path: RoutesEnum.MACs,
        exact: true,
        title: "MACs Images",
        component: Macs
    },
    {
        path: RoutesEnum.MACsEdit,
        exact: false,
        title: "Mac Edit",
        component: Mac,
    },
    {
        path: RoutesEnum.Tags,
        exact: true,
        title: "Content Tags",
        component: Tags,
    },
    {
        path: RoutesEnum.TagAdd,
        exact: true,
        title: "Add Content Tag",
        component: Tag,
    },
    {
        path: RoutesEnum.TagEdit,
        exact: true,
        title: "Edit Content Tag",
        component: Tag,
    },
];

export default Routes;
