import { TableBody } from "@material-ui/core";
import clsx from "clsx";
import Loader from "../Loader/Loader";
import NoDataMessage from "../NoDataMessage/NoDataMessage";
import { useStyles } from "./MUITable.styles";

interface iProps {
    isLoading?: boolean;
    rowsCount: number;
    hasError?: boolean;
}

const MUITableBodyWrapper: React.FC<iProps> = ({ children, isLoading = false, rowsCount, hasError }) => {
    const tableClasses = useStyles();

    return (
        <TableBody component="div" className={clsx(tableClasses.tbody, tableClasses.tbodyFull)}>
            {rowsCount > 0 && children}
            <Loader shown={isLoading} fixedOverlay centered />
            {rowsCount === 0 && !isLoading && <NoDataMessage hasError={hasError} />}
        </TableBody>
    );
};

export default MUITableBodyWrapper;
