import { useCallback, useEffect, useState } from "react";
import useFetch from "use-http";
import { getApiLink } from "../apiConfig";
import { iButtonCategory__API } from "../apiSchema";
import API from "../enums/API";
import { iCategory } from "../models/iCategory";

interface iAllCategories {
    titleSubcategories: iCategory[];
    buttonSubcategories: iCategory[];
}
const emptyCategories: iAllCategories = {
    titleSubcategories: [],
    buttonSubcategories: []
};
const useCategories = () => {
    const { data, response, abort, loading } = useFetch<iButtonCategory__API>(
        getApiLink(API.GET_CATEGORIES),
        {},
        [],
    );
    const [allCategories, setAllCategories] = useState<iAllCategories>(emptyCategories);
    const [categories, setCategories] = useState<iCategory[]>([]);
    const [subCategories, setSubCategories] = useState<iCategory[]>([]);

    const getAllCategories = useCallback(async () => {
        if (response.ok) {
            if ( !data ) return;

            setAllCategories({
                titleSubcategories: data.titleCategories || [],
                buttonSubcategories: data.buttonCategories || []
            });
        }
    }, [data, response.ok]);

    useEffect(() => {
        setCategories(allCategories.buttonSubcategories);
    }, [allCategories.buttonSubcategories]);

    useEffect(() => {
        setSubCategories(allCategories.titleSubcategories);
    }, [allCategories.titleSubcategories]);

    useEffect(() => {
        getAllCategories();
    }, [getAllCategories]);

    useEffect(() => {
        return () => {
            abort();
        };
    }, [abort]);

    return { categoriesIsLoading: loading, categories, subCategories };
};

export default useCategories;
