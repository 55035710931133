import React, { FC, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router";
import ConfirmPopup from "../../components/ConfirmPopup/ConfirmPopup";
import HeaderActions from "../../components/HeaderActions/HeaderActions";
import { Page, PageContent, PageHeader } from "../../components/PageLayout";
import useGetPublications from "../../core/api/useGetPublications";
import { getApiLink } from "../../core/apiConfig";
import API from "../../core/enums/API";
import RoutesEnum from "../../core/enums/RoutesEnum";
import { filterDataBySearch } from "../../core/helpers";
import useConfirmPopup from "../../hooks/useConfirmPopup";
import ActionButtons, { ActionButtonTypeEnum } from "../../components/ActionButtons/ActionButtons";
import AddIcon from "@material-ui/icons/Add";
import MUITable, { iTableConfigItem } from "../../components/MUITable/MUITable";
import EditIcon from "@material-ui/icons/Edit";
import ListIcon from "@material-ui/icons/List";
import DeleteIcon from "@material-ui/icons/Delete";
import { highlightValue } from "../../components/MUITable/MUITable.helpers";
import { iShortPublication } from "../../core/models/iPublication";

const Publications: FC = () => {
    let history = useHistory();
    const { data, loading, post, response, getPublications } = useGetPublications();
    const [searchQuery, setSearchQuery] = useState("");
    const removeConfirm = useConfirmPopup();

    const filteredData: iShortPublication[] = useMemo(
        () => filterDataBySearch(data, searchQuery),
        [data, searchQuery],
    );

    const showRemoveConfirm = useCallback(
        (id: number, title: string) => {
            removeConfirm.show(id, title);
        },
        [removeConfirm],
    );

    const handleRemovePublication = async () => {
        await post(getApiLink(API.DELETE_PUBLICATION, { id: removeConfirm.itemId }));

        if (response.ok) {
            getPublications();
        }
    };

    const tableConfig: iTableConfigItem[] = useMemo(() => [
        { title: 'ID', width: 70, Value: ({ index }) => <>{filteredData[index].id}</> },
        { title: 'Title', Value: ({ index }) => <>{highlightValue(filteredData[index].title, searchQuery)}</>},
        { title: 'Date', width: 150, Value: ({ index }) => <>{filteredData[index].createDate}</> },
        { title: 'Category', Value: ({ index }) => <>{filteredData[index].titleCategoryName}</> },
        {
            title: '',
            width: 150,
            Value: ({ index }) => {
                const item = filteredData[index];

                return (
                    <ActionButtons config={[
                        {
                            type: ActionButtonTypeEnum.LinkIconButton,
                            ariaLabel: 'Edit',
                            link: RoutesEnum.PublicationEdit.replace(':id', `${item.id}`),
                            Icon: EditIcon,
                        },
                        {
                            type: ActionButtonTypeEnum.LinkIconButton,
                            ariaLabel: 'Manage Chapters',
                            link: RoutesEnum.Chapters.replace(':id', `${item.id}`),
                            Icon: ListIcon,
                        },
                        {
                            ariaLabel: 'Delete',
                            Icon: DeleteIcon,
                            color: 'secondary',
                            onClick: () => showRemoveConfirm(item.id, item.title),
                        },
                    ]}/>
                )
            },
        },
    ], [filteredData, showRemoveConfirm, searchQuery]);

    return (
        <Page>
            <PageHeader title="Publications">
                <HeaderActions
                    searchQuery={searchQuery}
                    searchLabel="Search publications"
                    setSearchQuery={(value: string) => setSearchQuery(value)}
                    submitIcon={<AddIcon />}
                    submitText="Add publication"
                    onSubmit={() => history.push(RoutesEnum.PublicationAdd)}
                />
            </PageHeader>

            <PageContent>
                <MUITable isLoading={loading} config={tableConfig} rowsCount={filteredData.length}/>
                <ConfirmPopup
                    title={`Delete publication ${removeConfirm.itemTitle}`}
                    message="Are you sure to delete this item?"
                    shown={removeConfirm.shown}
                    onConfirm={handleRemovePublication}
                    closeModal={removeConfirm.hide}
                    submitButtonLabel="Delete"
                />
            </PageContent>
        </Page>
    );
};

export default Publications;
