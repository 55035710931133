import { useCallback, useEffect, useState } from "react";
import useFetch from "use-http";
import { getApiLink } from "../apiConfig";
import { iChapterAdmin__API } from "../apiSchema";
import API from "../enums/API";
import { iChapter } from "../models/iChapter";

const useGetChapters = (id: number) => {
    const { get, response, post, abort, loading = true } = useFetch<iChapterAdmin__API[]>();
    const [data, setData] = useState<iChapter[]>([]);

    const getData = useCallback(async (id: number) => {
        const chapters: iChapterAdmin__API[] = await get(getApiLink(API.GET_CHAPTERS, {publicationId: id}));

        if (response.ok) {
            setData(chapters.map(item => ({
                ...item,
                description: item.description || "",
                title: item.title || "",
                bigImageName: item.bigImageName || "",
            })));
        }
    }, [get, response]);

    useEffect(() => {
        if (!id) return;
        getData(id);
    }, [getData, id]);

    useEffect(() => {
        return () => {
            abort();
        }
    }, [abort]);

    return { data, setData, loading, getData, post, response }
}

export default useGetChapters;