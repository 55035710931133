import { Button, Divider, Grid, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import useUploadImage from "../../../core/api/useUploadImage";
import { getInputErrors, isDataValid } from "../../../core/formValidation";
import { iChapter } from "../../../core/models/iChapter";
import useGetFile from "../../../hooks/useGetFile";
import useNotification from "../../../hooks/useNotifications";
import DropareaBase from "../../Droparea/DropareaBase";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import { FormInput } from "../../Form";
import { iValidationData, validationConfig } from "./ManageChapter.helpers";
import { useStyles } from "./ManageChapter.styles";

interface iProps {
    closeModal: () => void;
    onSubmit: (isUpdateChapter: boolean, data: iChapter) => {};
    chapterId: number | null;
    data: iChapter;
}

const ManageChapter = ({ closeModal, onSubmit, chapterId, data }: iProps) => {
    const classes = useStyles();
    const [chapter, setChapter] = useState<iChapter>(data);

    const {
        uploadImage,
        response: uploadImageResponse,
        loading: uploadImageIsLoading,
    } = useUploadImage();
    const isUpdateChapter = !!chapterId;

    const [isValidationShown, setIsValidationShown] = useState(false);
    const validationData: iValidationData = {
        title: chapter.title,
        numberOfPages: chapter.numberOfPages,
        minutesToRead: chapter.minutesToRead,
        pageFrom: chapter.pageFrom,
        pageTo: chapter.pageTo,
    };
    const isValid: boolean = isDataValid(validationData)(validationConfig);

    const { addNotification } = useNotification();
    const {
        getImage: getLargeImage,
        image: largeImage,
        handleUpdateImage: setLargeImage,
        isLoading: largeImageIsLoading,
    } = useGetFile();

    const handleChangeField = (prop: keyof iChapter) => (value: any) => {
        setChapter(prevState => ({ ...prevState, [prop]: value }));
    };

    const handleUploadImage = useCallback(
        async (field: keyof iChapter, files: File[]) => {
            const fileUrl = await uploadImage(files);

            if (uploadImageResponse.ok) {
                setChapter(prevState => ({ ...prevState, [field]: fileUrl }));
            }
        },
        [uploadImageResponse.ok, uploadImage],
    );

    useEffect(() => {
        if (!chapter.bigImageName) return;
        const filename = chapter.bigImageName;
        getLargeImage(filename);
    }, [chapter.bigImageName, getLargeImage]);

    const handleAddImage = (field: keyof iChapter, fileObjs: any[]) => {
        handleUploadImage(
            field,
            fileObjs.map((file: any) => file.file),
        );
    };

    const fieldHasError = (field: keyof iValidationData) =>
        !!getInputErrors(validationData)(validationConfig)(field).length && isValidationShown;

    const fieldErrors = (field: keyof iValidationData) =>
        getInputErrors(validationData)(validationConfig)(field).map((err, idx) => (
            <div key={idx}>{err}</div>
        ));

    const handleSave = () => {
        if (isValid) {
            onSubmit(isUpdateChapter, chapter);
            setIsValidationShown(false);
        } else {
            setIsValidationShown(true);
            addNotification({
                message: <span>Please check validation errors</span>,
                status: "error",
            });
        }
    };

    return (
        <>
            <div className={classes.modalHeader}>
                <Typography variant="h5" component="h3">
                    {isUpdateChapter ? "Edit" : "Add"} chapter
                </Typography>

                <Button onClick={closeModal} className={classes.modalClose} disableRipple>
                    <CloseIcon />
                </Button>
            </div>
            <div className={classes.modalBody}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <FormInput
                            label="Title"
                            value={chapter.title}
                            onChange={value => handleChangeField("title")(value)}
                            hasError={fieldHasError("title")}
                            required
                        >
                            <ErrorMessage shown={isValidationShown}>
                                {fieldErrors("title")}
                            </ErrorMessage>
                        </FormInput>
                    </Grid>

                    <Divider className={classes.dividerHidden} />

                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <FormInput
                                    label="Description"
                                    value={chapter.description}
                                    onChange={value => handleChangeField("description")(value)}
                                    textarea
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <FormInput
                                            label="From page"
                                            value={chapter.pageFrom}
                                            onChange={value => handleChangeField("pageFrom")(value)}
                                            hasError={fieldHasError("pageFrom")}
                                            required
                                        >
                                            <ErrorMessage shown={isValidationShown}>
                                                {fieldErrors("pageFrom")}
                                            </ErrorMessage>
                                        </FormInput>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormInput
                                            label="To page"
                                            value={chapter.pageTo}
                                            onChange={value => handleChangeField("pageTo")(value)}
                                            hasError={fieldHasError("pageTo")}
                                            required
                                        >
                                            <ErrorMessage shown={isValidationShown}>
                                                {fieldErrors("pageTo")}
                                            </ErrorMessage>
                                        </FormInput>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormInput
                                            label="Number of pages"
                                            value={chapter.numberOfPages}
                                            onChange={value =>
                                                handleChangeField("numberOfPages")(value)
                                            }
                                            hasError={fieldHasError("numberOfPages")}
                                            required
                                        >
                                            <ErrorMessage shown={isValidationShown}>
                                                {fieldErrors("numberOfPages")}
                                            </ErrorMessage>
                                        </FormInput>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormInput
                                            label="Minutes to read"
                                            value={chapter.minutesToRead}
                                            onChange={value =>
                                                handleChangeField("minutesToRead")(value)
                                            }
                                            hasError={fieldHasError("minutesToRead")}
                                            required
                                        >
                                            <ErrorMessage shown={isValidationShown}>
                                                {fieldErrors("minutesToRead")}
                                            </ErrorMessage>
                                        </FormInput>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Divider className={classes.dividerHidden} />
                            <Grid item xs={5}>
                                <Grid container spacing={2}>
                                    <Grid item xs={7}>
                                        <Typography
                                            variant="subtitle2"
                                            component="h3"
                                            className={clsx(classes.mb)}
                                        >
                                            Large image
                                        </Typography>
                                        <DropareaBase
                                            acceptedFileTypes={["image/*"]}
                                            onAdd={fileObjs =>
                                                handleAddImage("bigImageName", fileObjs)
                                            }
                                            onDelete={() => {
                                                setLargeImage([]);
                                                handleChangeField("bigImageName")("");
                                            }}
                                            values={largeImage}
                                            dropzoneText="Drag and drop an image here or click"
                                            isLoading={largeImageIsLoading}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.modalFooter}>
                <div className={classes.modalActions}>
                    <Button onClick={closeModal}>Cancel</Button>

                    <Button
                        className={classes.submit}
                        variant="contained"
                        color="primary"
                        disabled={uploadImageIsLoading}
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ManageChapter;
