import { iTag__API } from "../apiSchema";

export interface iTag {
    id: number;
    title: string;
}

export interface iTagExtended extends iTag {
    publicationsIds: number[];
}

export interface iTagOption extends iTag {
    inputValue: string;
}

type ApiModel = iTag__API;

export class Tag implements iTag {
    id = Tag.defaultData.id;
    title = Tag.defaultData.title;

    static defaultData: iTag = {
        id: 0,
        title: '',
    };

    constructor(data?: {
        apiModel?: ApiModel,
    }) {
        if (data) {
            if (data.apiModel) {
                this.mapFromApi(data.apiModel);
            }
        }
    }

    private setData (model: iTag) {
        ({
            id: this.id,
            title: this.title,
        } = model);
    }

    private mapFromApi (model: ApiModel) {
        this.setData({
            id: model.id,
            title: model.value,
        });
    }
}

export class TagExtended implements iTagExtended {
    id = TagExtended.defaultData.id;
    title = TagExtended.defaultData.title;
    publicationsIds = TagExtended.defaultData.publicationsIds;

    static defaultData: iTagExtended = {
        id: 0,
        title: '',
        publicationsIds: [],
    };

    constructor(data?: {
        apiModel?: ApiModel,
        subModel?: iTagExtended,
    }) {
        if (data) {
            if (data.apiModel) {
                this.mapFromApi(data.apiModel);
            }
            else if (data.subModel) {
                this.setData(data.subModel);
            }
        }
    }

    private setData (model: iTagExtended) {
        ({
            id: this.id,
            title: this.title,
            publicationsIds: this.publicationsIds,
        } = model);
    }

    private mapFromApi (model: ApiModel) {
        this.setData({
            id: model.id,
            title: model.value,
            publicationsIds: model.publicationsIds || TagExtended.defaultData.publicationsIds,
        });
    }

    exportForBackEnd(): ApiModel {
        return {
            id: this.id,
            value: this.title,
            publicationsIds: this.publicationsIds,
        };
    }
}