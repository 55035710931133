import { getApiLink } from "../apiConfig";
import { iTagShort__API } from "../apiSchema";
import API from "../enums/API";
import { iTagExtended, TagExtended } from "../models/Tag";
import { useData } from "../../hooks/useData";
import useFetch from "use-http";

export default function useTagsShort () {
    const { get } = useFetch();
    async function getTags(): Promise<iTagExtended[]> {
        const response: iTagShort__API[] = await get(getApiLink(API.GET_TAGS_SHORT));
        return response.map(tag => new TagExtended({ apiModel: tag }));
    }

    return useData(([] as iTagExtended[]), getTags);
};