import { CachePolicies, IncomingOptions } from "use-http";

export function getApiLink(api: string, queryParams: any = {}) {
    const queryString = Object.keys(queryParams).reduce((acc, param) => {
        let paramString: string = "";
        if (Array.isArray(queryParams[param])) {
            if ( queryParams[param].length ) {
                paramString = queryParams[param].reduce((accc: string, val: string) => {
                    if (accc === "") {
                        return `${param}=${val}`;
                    }
                    return `${accc}&${param}=${val}`;
                }, "");
            } else {
                paramString = `${param}=`
            }


        } else {
            paramString = `${param}=${encodeURIComponent(queryParams[param])}`;
        }
        if (acc === "") {
            return `?${paramString}`;
        }
        return `${acc}&${paramString}`;
    }, "");

    return `/${api}${queryString}`;
}

export function getApiLinkWithDomain (api: string, queryParams: any = {}) {
    return `${process.env.REACT_APP_API_LINK}${getApiLink(api, queryParams)}`;
}

export const apiOptions: IncomingOptions = {
    cachePolicy: CachePolicies.NO_CACHE,
    credentials: "include",
    interceptors: {
        response: async ({ response }) => {
            if (!response.ok && response.status === 401) {
                window.location.href = `${process.env.REACT_APP_API_LINK}/userAuth?returnUrl=${window.location.href}`;
            }
            if (!response.ok && response.status === 403) {
                window.location.href = process.env.REACT_APP_CLIENT!;
            }

            if (!response.ok && response.status >= 500) {
                alert("Something went wrong");
            }

            return response;
        },
    },
};