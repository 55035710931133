import { useCallback, useEffect, useState } from "react";
import useFetch from "use-http";
import { getApiLink } from "../apiConfig";
import { iSubscriptionPackage__API } from "../apiSchema";
import API from "../enums/API";
import { iSubscriptionPackage } from "../models/iSubscriptionPackage";

const useGetSubscriptionPackages = () => {
    const { get, response, post, abort, loading = true } = useFetch<iSubscriptionPackage__API[]>();
    const [data, setData] = useState<iSubscriptionPackage[]>([]);

    const getSubscriptionPackages = useCallback(async () => {
        const packages = await get(getApiLink(API.GET_SUBSCRIPTION_PACKAGES));

        if (response.ok && packages) {
            setData(packages);
        }
    }, [get, response]);


    useEffect(() => {
        getSubscriptionPackages();
    }, [getSubscriptionPackages]);

    useEffect(() => {
        return () => {
            abort();
        }
    }, [abort]);

    return { data, loading, post, getSubscriptionPackages, response }
}

export default useGetSubscriptionPackages;