import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { useStyles } from "./Form.styles";
import { FC, useEffect, useState } from "react";
import { OutlinedInput } from "@material-ui/core";

interface iProps {
    label: string;
    onChange: (value: any) => void;
    value: (string | number) | (number | string)[];
    required?: boolean;
    isDisabled?: boolean;
    isLoading?: boolean;
    hasError?: boolean;
    options: { id: number; name: string }[];
}

const FormSelect: FC<iProps> = ({
    label,
    onChange,
    value,
    required,
    children,
    isDisabled = false,
    hasError,
    options,
    isLoading,
}) => {
    const classes = useStyles();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const [isMultiple, setIsMultiple] = useState(false);

    useEffect(() => {
        setIsMultiple(Array.isArray(value));
    }, [value]);

    const handleChange = (value: any) => {
        onChange(value);
    };

    const renderInputValue = (selected: any) => {
        if (!isMultiple) {
            const selectedItem = options.find(({id}) => id === selected);
            return selectedItem ? selectedItem.name : value;
        };
        return options
            .filter(({ id }) => selected.includes(id))
            .map(item => item.name)
            .join(", ");
    };
    const renderInput = <OutlinedInput error={hasError} label={required ? `${label} *` : label} />;

    const MenuProps: any = {
        getContentAnchorEl: null,
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
        },
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    return (
        <FormControl variant="outlined" className={classes.formInput}>
            <InputLabel required={required} error={hasError}>
                {label}
            </InputLabel>
            <Select
                multiple={isMultiple}
                value={value}
                onChange={e => handleChange(e.target.value)}
                input={renderInput}
                renderValue={selected => renderInputValue(selected)}
                MenuProps={MenuProps}
                required={required}
                disabled={isDisabled || isLoading}
                error={hasError}
            >
                {options.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                        {isMultiple && Array.isArray(value) && (
                            <>
                                <Checkbox color="primary" checked={value.includes(item.id)} />
                                <ListItemText primary={item.name} />
                            </>
                        )}

                        {!isMultiple && item.name}
                    </MenuItem>
                ))}
            </Select>

            {children}
        </FormControl>
    );
};

export default FormSelect;
