import { useCallback, useEffect, useState } from "react";
import useFetch from "use-http";
import { getApiLink } from "../apiConfig";
import { iPublicationAdmin__API } from "../apiSchema";
import API from "../enums/API";
import { Publication, iPublication } from "../models/iPublication";

const emptyPublication = new Publication();

const useGetPublication = (id: number) => {
    const [values, setValues] = useState<iPublication>(emptyPublication);
    const { get, post, data, abort, response, loading = true } = useFetch<iPublicationAdmin__API>({
        data: emptyPublication,
    });

    const getPublication = useCallback(
        async id => {
            const data = await get(getApiLink(API.GET_PUBLICATION, { id }));
            const publication = new Publication(data);

            if (response.ok) {
                setValues(publication);
            }
        },
        [get, response.ok],
    );

    useEffect(() => {
        if (id) {
            getPublication(id);
        }
    }, [id, getPublication]);

    useEffect(() => {
        return () => {
            abort();
        };
    }, [abort]);

    return { values, setValues, post, response, loading, data };
};

export default useGetPublication;
