import { addStyles } from "../../../core/addStyles";

const useStyles = addStyles(theme => ({
    mb: {
        marginBottom: theme.spacing(1)
    },
    error: {
        color: theme.palette.error.main
    },
    divider: {
        width: "100%",
        opacity: "0",
        margin: `${theme.spacing(2)}px 0`
    }
}));

export {useStyles};