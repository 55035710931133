import { iAdminRibbon__API } from "../apiSchema";

interface iRibbon {
    id: number;
    title: string;
    publicationIds: number[];
}

export default iRibbon;

interface iData {
    subModel?: Partial<iRibbon>;
    apiModel?: iAdminRibbon__API;
}

export class Ribbon implements iRibbon {
    id = Ribbon.defaultData.id;
    title = Ribbon.defaultData.title;
    publicationIds: number[] = Ribbon.defaultData.publicationIds;

    static defaultData: iRibbon = {
        id: 0,
        title: 'New ribbon',
        publicationIds: [],
    };

    constructor(data?: iData) {
        if (data) {
            const { apiModel, subModel } = data;
            if (apiModel) {
                this.mapFromApi(apiModel);
            }
            else if (subModel) {
                this.mapFromClient(subModel);
            }
        }
    }

    private setData(model: iRibbon) {
        ({
            id: this.id,
            title: this.title,
            publicationIds: this.publicationIds,
        } = model);
    }

    private mapFromClient(subModel: Partial<iRibbon>) {
        this.setData({
            ...Ribbon.defaultData,
            ...subModel,
        });
    }

    private mapFromApi(apiModel: iAdminRibbon__API) {
        const {
            name: title,
            ...rest
        } = apiModel;

        this.setData({
            ...rest,
            title,
        });
    }

    static mapForApi(clientModel: iRibbon): iAdminRibbon__API {
        const {
            title: name,
            ...rest
        } = clientModel;

        return {
            ...rest,
            name,
        };
    }

    mapForApi(): iAdminRibbon__API {
        const {
            title: name,
            ...rest
        } = this;

        return {
            ...rest,
            name,
        };
    }
}