import { InputAdornment, TextField } from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./Form.styles";

interface iProps {
    label: string;
    onChange: (value: any) => void;
    value: any;
    hasError?: boolean;
    required?: boolean;
    textarea?: boolean;
    adornment?: string;
    readOnly?: boolean;
}

const FormInput: React.FC<iProps> = ({
    label,
    onChange,
    value,
    hasError,
    required = false,
    textarea,
    adornment = "",
    children,
    readOnly = false,
}) => {
    const classes = useStyles();

    const handleChange = (value: string) => {
        onChange(value);
    };

    const rows = textarea ? 5 : 0;

    return (
        <div className={clsx(classes.formInput, textarea && classes.textarea)}>
            <TextField
                label={label}
                onChange={e => handleChange(e.target.value)}
                value={value}
                variant="outlined"
                fullWidth
                error={hasError}
                required={required}
                multiline={textarea}
                rows={rows}
                rowsMax={rows}
                className={clsx(textarea && classes.textarea)}
                InputProps={{
                    readOnly,
                    classes: {
                        root: clsx(textarea && classes.textarea),
                    },
                    startAdornment: (!!value.toString().length && adornment) ? (<InputAdornment position="start">{adornment}</InputAdornment>) : null
                }}
            />

            {children}
        </div>
    );
};

export default FormInput;
