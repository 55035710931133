import { addStyles } from "../../core/addStyles";
import { TABLE_ROW_SIZE } from "../../core/constants";

const useStyles = addStyles(theme => ({
    root: {
        flex: 1,
        display: "flex",
        flexDirection: "column"
    },
    tableContainer: {
        maxHeight: "calc(100vh - 190px)",
        minHeight: 350,
        flex: 1
    },

    table: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column"
    },
    list: {},
    thead: {
        height: TABLE_ROW_SIZE
    },
    tbody: {
        width: "100%",
        minHeight: "200px",
        display: "flex",
        position: "relative",
        overflow: "hidden",
        height: "100%"
    },
    tbodyFull: {
        // height: "calc(100vh - 250px)",
        overflow: "hidden",
        minHeight: 300
    },
    row: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
        boxSizing: "border-box",
        minWidth: "100%",
        width: "100%",
        "&:hover": {
            backgroundColor: theme.palette.action.selected,
        },
    },
    rowHighlight: {
        backgroundColor: theme.palette.action.hover,
    },
    headerRow: {
        backgroundColor: theme.palette.action.disabledBackground,
        height: "100%",

        "&:hover": {
            backgroundColor: theme.palette.action.disabledBackground,
        },
    },
    cell: {
        display: "block",
        flexGrow: 0,
        flexShrink: 0,
        height: "100%",
        position: "relative",
        "& b" : {
            background: theme.palette.warning.light,
        }
    },
    expandingCell: {
        flex: 1,
    },

    actions: {
        padding: 0,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: 200,
    },

    "@keyframes movingGradient": {
        "0%": { backgroundSize: "100% 100%" },
        "50%": { backgroundSize: "250% 100%" },
        "100%": { backgroundSize: "100% 100%" },
    },
    rowLoader: {
        height: "50%",
        display: "flex",
        width: "98%",
        background: "linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%)",
        backgroundSize: "100% 100%",
        animation: "$movingGradient 1000ms infinite linear forwards",
        margin: "auto",
    },
    rowLoading: {
        borderBottom: "1px solid #e0e0e0",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
}));

export { useStyles };
