import { FormControlLabel, Switch } from "@material-ui/core";
import { useStyles } from "./Form.styles";

interface iProps {
    label: string;
    onChange: (value: any) => void;
    checked: any;
}

const FormToggle: React.FC<iProps> = ({ label, onChange, checked }) => {
    const classes = useStyles();

    const handleChange = (value: boolean) => {
        onChange(value);
    };

    return (
        <div className={classes.formToggle}>
            <FormControlLabel
                control={
                    <Switch
                        checked={checked}
                        onChange={e => handleChange(e.target.checked)}
                        name={label}
                        color="primary"
                    />
                }
                label={label}
                labelPlacement="start"
                className={classes.inputFixedHeight}
            />
        </div>
    );
};

export default FormToggle;
