enum API {
    GET_CHAPTERS = "Chapter/GetChapters",
    EDIT_CHAPTER = "Chapter/Edit",
    CREATE_CHAPTER = "Chapter/CreateChapter",
    DELETE_CHAPTER = "Chapter/Delete",
    GET_TAG = "Tag/Get",
    SAVE_TAG = "Tag/Save",
    DELETE_TAG = "Tag/Delete",
    GET_TAGS = "Tag/GetAll",
    GET_TAGS_SHORT = "Tag/GetAllShort",
    GET_PUBLICATION = "Publication/Get",
    GET_SHORT_PUBLICATION = "Publication/GetAllShort",
    SAVE_PUBLICATION = "Publication/Save",
    DELETE_PUBLICATION = "Publication/Delete",
    GET_CATEGORIES = "Category/GetAllCategories",
    GET_SUBCATEGORIES = "Category/GetSubcategories",
    GET_FILE = "File/Get",
    UPLOAD_FILE = "File/Upload",
    GET_IMAGE = "Image",
    UPLOAD_IMAGE = "Image/Upload",
    GET_RIBBONS = "Ribbon/GetAdminRibbons",
    GET_RIBBON = "Ribbon/GetAdminRibbon",
    RIBBONS_ORDERED_IDS = "Ribbon/AdminRibbonsOrderedIds",
    DELETE_RIBBON = "Ribbon/Delete",
    SAVE_RIBBON = "Ribbon/SaveAdminRibbon",
    GET_FEATURED = "FeaturedContent/Get",
    SAVE_FEATURED = "FeaturedContent/Update",
    GET_SUBSCRIPTION_PACKAGES = "SubscriptionPackage/Get",
    GET_USER = "User/GetUser",
    TEST_FLIGHT = "Tag/TestFlight",
    GET_PUBLICATIONS_WITH_CHAPTERS = "Publication/GetPublicationsWithChapters",
    GET_SNAP_PUBLICATIONS_WITH_CHAPTERS = "Publication/GetSnapPublicationsWithChapters",
    GET_ALL_MACS = "SnapDocument/GetAllMacs",
    SAVE_MAC = "SnapDocument/SaveMac",
    GET_MAC = "SnapDocument/GetMac",

}

export default API;