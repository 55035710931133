import { useCallback, useEffect, useState } from "react";
import useFetch from "use-http";
import { getApiLink } from "../apiConfig";
import API from "../enums/API";
import { Mac } from "../models/iMac";
import { iMacSnapLight__API } from "../apiSchema";


const useGetMac = (id: number) => {
    const [values, setValues] = useState<Mac>(new Mac());
    const { get, post, abort, response, loading = true } = useFetch<iMacSnapLight__API>({
        data: new Mac(),
    });

    const getMac = useCallback(
        async id => {
            const mac: iMacSnapLight__API = await get(getApiLink(API.GET_MAC, { snapId: id }));
            if (mac) {
                setValues(new Mac(mac));
            }
        },
        [get],
    );

    useEffect(() => {
        if (id) {
            getMac(id);
        }
    }, [id, getMac]);

    useEffect(() => {
        return () => {
            abort();
        };
    }, [abort]);

    return {
        data: values,
        setData: setValues,
        post,
        response,
        loading
    };
};

export default useGetMac;
