import MUITableCellWrapper, { iProps as iCellWrapperProps } from "./MUITableCellWrapper";
import { FC } from "react";
import { iTableConfigItem } from "./MUITable";

interface iProps {
    columnData: iTableConfigItem;
    props: Partial<iCellWrapperProps>;
}

const MUITableCell: FC<iProps> = (
    {
        columnData,
        props,
        children,
    }
) => {
    const allProps: iCellWrapperProps = {
        ...props,
    };

    if (columnData.width) {
        allProps.width = columnData.width;
    } else {
        allProps.fillRow = true;
    }
    return (
        <MUITableCellWrapper {...allProps}>
            {children}
        </MUITableCellWrapper>
    );

};

export default MUITableCell;