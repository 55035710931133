import { FilterOptionsState } from "@material-ui/lab";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import {
    iFormConfigItem,
    isNonNegativeNumber,
    isNotEmpty,
    isNotEmptyArray,
    isPositiveNumber,
} from "../../core/formValidation";
import { iTagOption } from "../../core/models/Tag";
import qs from "query-string";
import { iPublication } from "../../core/models/iPublication";
import { transformDate } from "../../core/helpers";
import { iFile__API } from "../../core/apiSchema";

const filter = createFilterOptions<iTagOption>();

export const getOptionLabel = (option: iTagOption): string => {
    if (typeof option === "string") {
        return option;
    }

    if (option.inputValue) {
        return option.inputValue;
    }

    return option.title;
};

export const filterOptions = (
    options: iTagOption[],
    params: FilterOptionsState<iTagOption>,
): iTagOption[] => {
    const filtered = filter(options, params);
    if (!filtered.length && params.inputValue.trim() !== "") {
        filtered.push({
            inputValue: params.inputValue.trimLeft().trim(),
            title: `Add "${params.inputValue}"`,
            id: 0,
        });
    }
    return filtered;
};

export interface iValidationData {
    title: string;
    bigImageName: string;
    attachedFiles: iFile__API[];
    numberOfPages: number;
    price: number | null;
    minutesToRead: number;
    categoryId: number;
    subCategoryId: number;
    createDate: string;
}

export const validationConfig: iFormConfigItem<iValidationData>[] = [
    {
        field: "title",
        validationCheckers: [isNotEmpty]
    },
    {
        field: "numberOfPages",
        validationCheckers: [isNonNegativeNumber]
    },
    {
        field: "minutesToRead",
        validationCheckers: [isNonNegativeNumber]
    },
    {
        field: "createDate",
        validationCheckers: [isNotEmpty]
    },
    {
        field: "price",
        validationCheckers: [isNonNegativeNumber]
    },
    {
        field: "bigImageName",
        validationCheckers: [isNotEmpty],
        message: "Should be attached large image",
    },
    {
        field: "attachedFiles",
        validationCheckers: [isNotEmptyArray],
        message: "Should be attached at least one file"
    },
    {
        field: "categoryId",
        validationCheckers: [isPositiveNumber],
        message: "should be selected one of categories",
    },
    {
        field: "subCategoryId",
        validationCheckers: [isPositiveNumber],
        message: "should be selected one of subcategories",
    },
];

export const getFilenameFromUrl = (url: string) => {
    const parsedUrl = qs.parseUrl(url);
    const filename = parsedUrl.query.filename;
    return filename;
};


export const transformDataToBackend = (data: iPublication) => {
    const {
        filePDF,
        filePPT,
        fileOperatorIdeaXLS,
        fileConsumerIdeaXLS,
        fileSamplePDF,
        ...publication
    } = data;

    const files = [
        filePDF,
        filePPT,
        fileOperatorIdeaXLS,
        fileConsumerIdeaXLS,
        fileSamplePDF,
    ].filter(el => el);

    return {
        ...publication,
        numberOfPages: data.numberOfPages ? +data.numberOfPages : 0,
        minutesToRead: data.minutesToRead ? +data.minutesToRead : 0,
        files,
        price: data.price || data.price === 0 ? +data.price : null,
        createDate: transformDate(data.createDate),
    };
};


export const getBlankFile = (file: iFile__API | null) => {
    if (!file) return [];

    const emptyFile = (filename: string) => {
        return {
            file: new File([""], filename),
            data: "",
        };
    };

    return [emptyFile(file.filename)];
};

export const formatValueToNumber = (value: string) => +(value.replace(/\D/g, ''));