import { Button, TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useStyles } from "./HeaderActions.styles";

import RoutesEnum from "../../core/enums/RoutesEnum";

interface iProps {
    onSubmit?: () => void;
    searchQuery?: string;
    setSearchQuery?: (value: string) => void;
    searchLabel?: string;
    submitText?: string;
    submitIsDisabled?: boolean;
    submitIcon?: any;
    hideSearch?: boolean;
    backwardUrl?: RoutesEnum;
}

const HeaderActions: React.FC<iProps> = ({
    searchQuery = "",
    setSearchQuery = () => {},
    searchLabel = "",
    onSubmit,
    submitIcon = null,
    submitText = "",
    hideSearch = false,
    backwardUrl = "",
    submitIsDisabled = false
}) => {
    const classes = useStyles();

    const handleChangeSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    return (
        <>
            {!hideSearch && (
                <TextField
                    type="search"
                    value={searchQuery}
                    onChange={handleChangeSearchQuery}
                    label={searchLabel}
                    variant="outlined"
                    size="small"
                />
            )}

            <div className={classes.actions}>
                {backwardUrl && (
                    <Link to={backwardUrl} className={classes.backward}>
                        <Button>Cancel</Button>
                    </Link>
                )}

                {onSubmit && (
                    <Button
                        className={classes.submit}
                        variant="contained"
                        color="primary"
                        startIcon={submitIcon}
                        onClick={onSubmit}
                        disabled={submitIsDisabled}
                    >
                        {submitText}
                    </Button>
                )}
            </div>
        </>
    );
};

export default HeaderActions;
