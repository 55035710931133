import { TableHead } from "@material-ui/core";
import { useStyles } from "./MUITable.styles";

interface iProps {}

const MUITableHeadWrapper: React.FC<iProps> = ({children}) => {
    const tableClasses = useStyles();

    return (
        <TableHead component="div" className={tableClasses.thead}>
            {children}
        </TableHead>
    );
};

export default MUITableHeadWrapper;
