import { Grid } from "@material-ui/core";

const Page: React.FC = ({ children }) => {
    return (
        <Grid container>
            {children}
        </Grid>
    );
};

export default Page;
