
import { FC } from 'react';
import { addStyles } from '../../core/addStyles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

interface iProps {
    placeholder?: string;
}

export const DropareaIcon:FC<iProps> = ({ placeholder }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CloudUploadIcon className={classes.icon} />
            {placeholder && <div className={classes.text}>{placeholder}</div>}
        </div>
    );
};

const useStyles = addStyles(theme => ({
    root: {

    },
    icon: {
        width: 51,
        height: 51,
    },
    text: {
        fontStyle: 'italic',
        fontSize: '12px',
        color: theme.palette.grey[500],
    }
}));
