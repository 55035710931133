enum RoutesEnum {
    Home = "/",
    Publications = "/publications",
    PublicationEdit = "/publications/:id/edit",
    PublicationAdd = "/publications/add",
    Ribbons = "/ribbons",
    RibbonAdd = "/ribbons/add",
    RibbonEdit = "/ribbons/edit/:id",
    Chapters = "/publication/:id/chapters",
    FeaturedContent = "/featured",
    CompareChapters = "/compareChapters",
    MACs = "/macs",
    MACsEdit = "/macs/:id/edit",
    Tags = "/tags",
    TagAdd = "/tags/add",
    TagEdit = "/tags/edit/:id",
}

export default RoutesEnum;
