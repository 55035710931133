import { ReactComponent as Order } from "./order.svg";
import { ReactComponent as OrderArrows } from "./order-arrows.svg";
import { ReactComponent as OrderReverse } from "./order-reverse.svg";
import { FunctionComponent, SVGProps } from "react";

type IconType = FunctionComponent<SVGProps<SVGSVGElement>>;

export const IconOrder: IconType = Order;
export const IconOrderArrows: IconType = OrderArrows;
export const IconOrderReverse: IconType = OrderReverse;
