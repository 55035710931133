import { AppBar, IconButton, Toolbar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import useStyles from "../App/App.styles";
import React from "react";

interface iHeaderProps {
    togglePanel: (value: boolean) => void;
    showPanel: boolean;
}

const Header = ({ showPanel, togglePanel }: iHeaderProps) => {
    const classes = useStyles();

    return (
        <AppBar position="absolute" className={clsx(classes.appBar, showPanel && classes.appBarShift)}>
            <Toolbar className={classes.toolbar}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => togglePanel(true)}
                    className={clsx(classes.menuButton, showPanel && classes.menuButtonHidden)}>
                    <MenuIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
