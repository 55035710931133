import { Grid } from "@material-ui/core";
import { useStyles } from "./Page.styles";

interface iProps {
    title?: string;
}
const PageSubHeader: React.FC<iProps> = ({ title, children }) => {
    const classes = useStyles();
    return (
        <Grid container className={classes.header} justify={"space-between"} >
            {children}
        </Grid>
    );
};

export default PageSubHeader;
