import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Page, PageContent, PageHeader } from "../../components/PageLayout";
import HeaderActions from "../../components/HeaderActions/HeaderActions";
import useGetPublications from "../../core/api/useGetPublications";
import MUITable, { iTableConfigItem } from "../../components/MUITable/MUITable";
import ActionButtons from "../../components/ActionButtons/ActionButtons";
import DeleteIcon from "@material-ui/icons/Delete";
import PageSubHeader from "../../components/PageLayout/PageSubHeader";
import FormAutocomplete, { iAutocompleteOption } from "../../components/Form/FormAutocomplete";
import { Grid } from "@material-ui/core";
import { featuredContentValidationConfig, iFeaturedContentValidationData } from "./FeaturedContent.helpers";
import { getInputErrors, isDataValid } from "../../core/formValidation";
import useNotification from "../../hooks/useNotifications";
import { getApiLink } from "../../core/apiConfig";
import API from "../../core/enums/API";
import useGetFeatured from "../../core/api/useGetFeatured";
import HighlightValue from "../../components/Form/HighlightValue";
import { useStyles } from "./FeaturedContent.styles";

const FeaturedContent: FC = () => {
    const classes = useStyles();
    const { data, updateData, loading, post, response } = useGetFeatured();
    const { data: publications, loading: publicationsLoading } = useGetPublications();
    const [publicationsToOptions, setPublicationsToOptions] = useState<iAutocompleteOption[]>([]);
    const { addNotification } = useNotification();
    const [isValidationShown, setIsValidationShown] = useState(false);
    const validationData: iFeaturedContentValidationData = {
        data
    };
    const isValid: boolean = isDataValid(validationData)(featuredContentValidationConfig);

    const featuredPublications = useMemo(
        () => data
            .filter(id => publications.some(i => i.id === id))
            .map(id => publications.find(i => i.id === id)!),
        [data, publications],
    );

    const handleChangeAutocomplete = (selectedPublications: iAutocompleteOption[]) => {
        if ( !selectedPublications.length ) return;
        const { id } = selectedPublications[0];
        if ( id ) {
            updateData([...data, id ]);
        }
    }

    const handleRemovePublication = useCallback((id: number) => {
        updateData(data.filter(publicationId => publicationId !== id));
    }, [data, updateData]);

    useEffect(() => {
        if ( !publications.length ) return;
        const filtered = publications.filter(item => !data.includes(item.id));
        setPublicationsToOptions(
            filtered.map(
                (
                    {id, title, titleCategoryName, createDate}
                ) => (
                    {id, value: `${title} / ${titleCategoryName} / ${createDate}`}
                )
            )
        );

    }, [data, publications]);

    const tableConfig: iTableConfigItem[] = useMemo(() => [
        { title: 'ID', width: 70, Value: ({ index }) => <>{featuredPublications[index].id}</> },
        { title: 'Title', Value: ({ index }) => <>{featuredPublications[index].title}</> },
        { title: 'Date', width: 150, Value: ({ index }) => <>{featuredPublications[index].createDate}</> },
        { title: 'Category', Value: ({ index }) => <>{featuredPublications[index].titleCategoryName}</> },
        {
            title: '',
            width: 100,
            Value: ({ index }) => {
                const item = featuredPublications[index];

                return (
                    <ActionButtons config={[
                        {
                            ariaLabel: 'Delete',
                            Icon: DeleteIcon,
                            color: 'secondary',
                            onClick: () => handleRemovePublication(item.id),
                        },
                    ]}/>
                )
            },
        },
    ], [featuredPublications, handleRemovePublication]);

    const fieldHasError = (field: keyof iFeaturedContentValidationData) =>
        !!getInputErrors(validationData)(featuredContentValidationConfig)(field).length && isValidationShown;

    const handleSave = async () => {
        if (isValid) {
            setIsValidationShown(false);
            await post(getApiLink(API.SAVE_FEATURED), data);
            setIsValidationShown(false);

            if (response.ok) {
                addNotification({
                    message: <span>Featured Content is saved.</span>,
                    status: "success",
                });
            }
        } else {
            setIsValidationShown(true);
            addNotification({
                message: <span>Please check validation errors</span>,
                status: "error",
            });

        }
    }

    const customRenderOption = (option: iAutocompleteOption, value: string) => {
        const words = option.value.split(" / ");
        const title = words[0];
        const category = words[1];
        const date = words[2];
        const highlighTitle = HighlightValue({ option: { ...option, value: title}, inputValue: value });
        const highlightCategory = HighlightValue({ option: { ...option, value: category}, inputValue: value });
        return (
            <div className={classes.option}>
                <div className={classes.optionTitle}>{highlighTitle}</div>
                <div className={classes.optionInfo}>
                    {date && <span className={classes.optionDate}>{date}</span>}
                    {category && <span className={classes.optionCategory}>{highlightCategory}</span>}
                </div>
            </div>
        );
    };

    return (
        <Page>
            <PageHeader title="Manage Featured Content List">
                <HeaderActions
                    hideSearch
                    submitText="Save"
                    onSubmit={handleSave}
                />
            </PageHeader>

            <PageContent>
                <PageSubHeader>
                    <Grid item xs={4}>
                        <FormAutocomplete
                            label="Publications"
                            options={publicationsToOptions}
                            value={[]}
                            onChange={handleChangeAutocomplete}
                            isDisabled={loading || publicationsLoading}
                            renderOption={customRenderOption}
                            blurOnSelect
                            optionHeight={50}
                        />
                    </Grid>
                </PageSubHeader>
                <MUITable
                    isLoading={loading || publicationsLoading}
                    config={tableConfig}
                    rowsCount={featuredPublications.length}
                    hasError={fieldHasError("data")}
                />
            </PageContent>
        </Page>
    );
};

export default FeaturedContent;