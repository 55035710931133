import { addStyles } from "../../core/addStyles";

const useStyles = addStyles(theme => ({
    header: {
        marginBottom: theme.spacing(4),
        alignItems: "center",
    },
    actions: {
        display: "flex",
        justifyContent: "flex-end",
        height: "100%",
    },
    content: {
        minHeight: `calc(100vh - 180px)`,
        position: "relative",
        display: "flex",
        flexDirection: "column"
    },
}));

export { useStyles };
