import { addStyles } from "../../../core/addStyles";
import { STYLES } from "../../../core/constants";

const useStyles = addStyles(theme => ({
    submit: {
        minWidth: STYLES.MIN_WIDTH_BTN,
        height: "100%",
    },
    modalHeader: {
        height: 75,
        borderBottom: "1px solid rgba(0, 0, 0, 0.23)",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: theme.spacing(0, 2),
        boxShadow: "0 4px 6px -4px rgba(0,0,0,.25)",
        position: "relative",
        zIndex: 1
    },
    modalFooter: {
        height: 75,
        borderTop: "1px solid rgba(0, 0, 0, 0.23)",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: theme.spacing(0, 2),
        boxShadow: "0 -4px 6px -4px rgba(0,0,0,.25)",
        position: "relative",
        zIndex: 1
    },
    modalBody: {
        maxHeight: "calc(90vh - 150px)",
        overflow: "auto",
        padding: theme.spacing(2),
        background: theme.palette.background.paper
    },
    modalClose: {
        height: "100%",
        marginRight: theme.spacing(-2),
        "&:hover": {
            background: "none",
            color: theme.palette.error.main,
        },
    },
    modalActions: {
        marginLeft: "auto",
        "& > * ": {
            marginLeft: theme.spacing(2),
        },
    },
    textarea: {
        height: "100%",
    },
    divider: {
        background: "black",
        opacity: "0.2",
        width: "100%",
        margin: `${theme.spacing(2)}px 0`,
    },
    dividerHidden: {
        opacity: "0",
    },
    mb: {
        marginBottom: theme.spacing(1)
    },
    error: {
        color: theme.palette.error.main
    }
}));

export { useStyles };
