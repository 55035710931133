import { DropzoneAreaBase } from "material-ui-dropzone";
import { useStyles } from "./Droparea.styles";
import clsx from "clsx";
import { useEffect, useState, useMemo } from "react";
import Loader from "../Loader/Loader";
import { maxFileSizeInBytes } from "../../core/helpers";
import { DropareaIcon } from "./DropareaIcon";

interface iProps {
    acceptedFileTypes: string[];
    onAdd: (files: any[]) => void;
    onDelete: (file: any) => void;
    error?: boolean;
    values: any[];
    dropzoneText?: string;
    isLoading?: boolean;
    placeholder?: string;
}

const DropareaBase: React.FC<iProps> = ({
    acceptedFileTypes,
    onAdd,
    onDelete,
    error,
    values = [],
    dropzoneText = "",
    isLoading = false,
    placeholder = '',
}) => {
    const classes = useStyles();
    const [isActive, setIsActive] = useState(false);

    const handleAdd = (files: any[]) => {
        setIsActive(files.length ? true : false);
        onAdd(files);
    };

    const handleRemove = (file: any) => {
        setIsActive(false);
        onDelete(file);
    };

    useEffect(() => {
        setIsActive(!!values.length || isLoading);
    }, [values, isLoading]);

    const Icon = useMemo(() => () => {
        if ( isLoading || isActive ) return null;
        return <DropareaIcon placeholder={placeholder} />
    }, [placeholder, isLoading, isActive]);

    return (
        <div className={clsx(classes.droparea, isActive && classes.dropareaActive)}>
            {isLoading && (
                <div className={classes.loadingContainer}>
                    <Loader shown={isLoading} />
                </div>
            )}

            <DropzoneAreaBase
                fileObjects={values}
                onAdd={fileObjs => handleAdd(fileObjs)}
                onDelete={fileObj => handleRemove(fileObj)}
                acceptedFiles={acceptedFileTypes}
                dropzoneText={dropzoneText}
                filesLimit={1}
                showAlerts={['error']}
                showFileNames={false}
                previewGridProps={{ container: { spacing: 1, direction: "row" } }}
                showPreviewsInDropzone={false}
                dropzoneParagraphClass={clsx(classes.text, isActive && classes.textActive)}
                showPreviews={true}
                showFileNamesInPreview={false}
                maxFileSize={maxFileSizeInBytes}
                dropzoneClass={clsx(
                    classes.dropzone,
                    isActive && classes.dropzoneActive,
                    error && classes.dropzoneError,
                )}
                previewText={""}
                previewGridClasses={{
                    container: classes.previewContainer,
                    item: classes.preview,
                }}
                Icon={Icon as any}
            />
        </div>
    );
};

export default DropareaBase;
