import { Divider, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { FormInput } from "../../../components/Form";
import HeaderActions from "../../../components/HeaderActions/HeaderActions";
import Loader from "../../../components/Loader/Loader";
import { Page, PageContent, PageHeader } from "../../../components/PageLayout";
import RoutesEnum from "../../../core/enums/RoutesEnum";
import { iMac, Mac } from "../../../core/models/iMac";
import { useStyles } from "./Mac.styles";
import FormImageInput from "../../../components/Form/FormImageInput";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import { getInputErrors, isDataValid } from "../../../core/formValidation";
import { iValidationData, validationConfig } from "./Mac.helpers";
import useNotification from "../../../hooks/useNotifications";
import useGetMac from "../../../core/api/useGetMac";
import { getApiLink } from "../../../core/apiConfig";
import API from "../../../core/enums/API";

const MacPage = () => {
    const history = useHistory();
    const { addNotification } = useNotification();
    const classes = useStyles();

    const { params } = useRouteMatch<{ id: string }>();
    const macId = useMemo(() => Number(params.id), [params.id]);

    const { data, response, loading: isLoading, post } = useGetMac(macId);
    const [mac, setMac] = useState<Mac>(new Mac());

    const validationData: iValidationData = {
        bigImageName: mac.bigImageName
    };
    const [isValidationShown, setIsValidationShown] = useState(false);
    const fieldHasError = (field: keyof iValidationData) => {
        return !!getInputErrors(validationData)(validationConfig)(field).length && isValidationShown
    };
    const fieldErrors = (field: keyof iValidationData) => {
        return getInputErrors(validationData)(validationConfig)(field).map((err, idx) => (
            <div key={idx}>{err}</div>
        ));
    };
    const isValid: boolean = isDataValid(validationData)(validationConfig);

    useEffect(() => setMac(data), [data]);

    const handleChangeField = (prop: keyof iMac) => (value: any) => {
        setMac(prevState => new Mac().setData({ ...prevState, [prop]: value }));
    };

    const handleSaveMac = async () => {
        if ( isValid ) {
            await post(getApiLink(API.SAVE_MAC), mac.mapForApi());
            setIsValidationShown(false);
            if (response.ok) {
                addNotification({
                    message: <span>Mac saved</span>,
                    status: "success",
                });
                history.push(RoutesEnum.MACs);
            }
        } else {
            setIsValidationShown(true);
            addNotification({
                message: <span>Please check validation errors</span>,
                status: "error",
            });
        }
    }

    return (
        <Page>
            <PageHeader title="Edit MAC">
                <HeaderActions
                    hideSearch
                    submitText="Save"
                    submitIsDisabled={isLoading}
                    onSubmit={handleSaveMac}
                    backwardUrl={RoutesEnum.MACs}
                />
            </PageHeader>

            <PageContent>
                <Loader shown={isLoading} fixedOverlay />

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <FormInput
                            label="Title"
                            value={mac.title}
                            onChange={value => handleChangeField("title")(value)}
                            readOnly
                        />
                    </Grid>
                    <Divider className={classes.divider}  />
                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle2"
                            component="h3"
                            className={clsx(
                                classes.mb,
                                fieldHasError("bigImageName") && classes.error,
                            )}
                        >
                            Cover Image*
                        </Typography>

                        <FormImageInput
                            filename={mac.bigImageName}
                            onChange={filename => handleChangeField("bigImageName")(filename)}
                            onRemove={() => handleChangeField("bigImageName")("")}
                            error={fieldHasError("bigImageName")}
                        />
                        <ErrorMessage shown={isValidationShown}>
                            {fieldErrors("bigImageName")}
                        </ErrorMessage>
                    </Grid>
                </Grid>
            </PageContent>
        </Page>
    );
};

export default MacPage;
