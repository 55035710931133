import { PropTypes, IconButton, SvgIcon } from "@material-ui/core";
import { Link } from "react-router-dom";
import { FC } from "react";
import './ActionButtons.styles.scss';

export enum ActionButtonTypeEnum {
    IconButton,
    LinkIconButton,
}

interface iActionButtonConfig {
    Icon: typeof SvgIcon;

    type?: ActionButtonTypeEnum;
    onClick?: () => void;
    link?: string;

    ariaLabel?: string;
    color?: PropTypes.Color;
    size?: 'inherit' | 'default' | 'small' | 'large';
}

interface iProps {
    config: iActionButtonConfig[];
}

const ActionButtons: FC<iProps> = (
    {
        config
    }
) => {

    return (
        <div className="ActionButtons">
            {config.map((
                {
                    ariaLabel= '',
                    color,
                    onClick,
                    link= '',
                    type= ActionButtonTypeEnum.IconButton,
                    Icon,
                    size= 'small',
                }, index
            ) => {
                const renderButton = (
                    <IconButton
                        artia-label={ariaLabel}
                        color={color}
                        onClick={onClick}
                    >
                        <Icon fontSize={size}/>
                    </IconButton>
                );
                switch (type) {
                    case ActionButtonTypeEnum.LinkIconButton: return (
                        <Link key={index} to={link}>
                            {renderButton}
                        </Link>
                    );
                    default: return (
                        <div key={index}>
                            {renderButton}
                        </div>
                    );
                }

            })}
        </div>
    );
};

export default ActionButtons;
