import { addStyles } from "../../core/addStyles";

const useStyles = addStyles(theme => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        width: "80%",
        maxHeight: "90%",
        "&:focus": {
            outline: "none",
            border: "none",
        },
    },
}));

export { useStyles };
