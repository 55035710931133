import "./ScrollbarThumb.styles.scss";

const RenderVerticalThumb: React.FC = (props: any) => {
    const thumbStyle = {
        borderRadius: "20px",
        zIndex: 200,
    };
    return (
        <div
            {...props}
            style={{ ...props.style, ...thumbStyle }}
            className="ScrollbarThumb is-vertical"

        />
    );
};

export default RenderVerticalThumb;