import React, { createContext, FC, ReactNode } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import MUITableWrapper from "./MUITableWrapper";
import MUITableHeadWrapper from "./MUITableHeadWrapper";
import MUITableHead from "./MUITableHead";
import MUITableRow from "./MUITableRow";
import { TABLE_ROW_SIZE } from "../../core/constants";
import MUITableBodyWrapper from "./MUITableBodyWrapper";
import CustomScrollbarsVirtualList from "../CustomScrollbarsVirtualList/CustomScrollbarsVirtualList";

export interface iTableCellStyles {
    cell?: React.CSSProperties;
    head?: React.CSSProperties;
}


export interface iTableConfigItem {
    title?: string;
    renderTitle?: ReactNode;
    width?: number | string;
    Value: FC<{ index: number }>;
    tooltip?: string;
    textCentered?: boolean;
    styles?: iTableCellStyles;
    handleHeaderClick?: () => void;
    icon?: ReactNode;
    isActive?: boolean;
    isVisibleOnHover?: boolean;
}

interface iTableContextData {
    config: iTableConfigItem[];
}

export const TableContext = createContext<iTableContextData>({
    config: []
});

export interface iTableProps {
    config: iTableConfigItem[];
    isLoading?: boolean;
    rowsCount:  number;
    rowHeight?: number;
    useIsScrolling?: boolean;
    hasError?: boolean;
}

const MUITable: FC<iTableProps> = (
    {
        config,
        isLoading= false,
        rowsCount,
        rowHeight = TABLE_ROW_SIZE,
        useIsScrolling,
        hasError = false
    }
) => {
    return (
        <TableContext.Provider value={{ config }}>
            <MUITableWrapper>
                <MUITableHeadWrapper>
                    <MUITableHead/>
                </MUITableHeadWrapper>

                <MUITableBodyWrapper hasError={hasError} isLoading={isLoading} rowsCount={rowsCount}>
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                height={height}
                                width={width}
                                itemCount={rowsCount}
                                itemSize={rowHeight}
                                useIsScrolling={useIsScrolling}
                                outerElementType={CustomScrollbarsVirtualList}
                            >
                                {MUITableRow}
                            </List>
                        )}
                    </AutoSizer>
                </MUITableBodyWrapper>
            </MUITableWrapper>
        </TableContext.Provider>
    );
};

export default MUITable;