import { TableCell } from "@material-ui/core";
import clsx from "clsx";
import { TABLE_ROW_SIZE } from "../../core/constants";
import { useStyles } from "./MUITable.styles";

export interface iProps {
    head?: boolean;
    style?: React.CSSProperties;
    width?: number | string;
    fillRow?: boolean;
    textCentered?: boolean;
    onClick?: () => void;
}
const MUITableCellWrapper: React.FC<iProps> = (
    {
        children,
        head = false,
        style = {},
        width = "auto",
        fillRow = false,
        textCentered,
        onClick= () => null,
    }
) => {
    const tableClasses = useStyles();
    const align = textCentered ? "center" as const : "start" as const;
    const styles = {
        flexGrow: fillRow ? 1 : 0,
        flexShrink: fillRow ? 1 : 0,
        flexBasis: fillRow ? "0%" : width,
        height: TABLE_ROW_SIZE,
        lineHeight: 1,
        textAlign: align,
        ...style
    };

    return (
        <TableCell
            component="div"
            variant={head ? "head" : "body"}
            className={clsx(tableClasses.cell)}
            style={styles}
            scope="col"
            onClick={onClick}
        >
            {children}
        </TableCell>
    );
};

export default MUITableCellWrapper;
