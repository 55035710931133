import { TableRow } from "@material-ui/core";
import clsx from "clsx";
import { isEven } from "../../core/helpers";
import { useStyles } from "./MUITable.styles";
import { FC } from "react";

interface iProps {
    head?: boolean;
    index?: number;
    style?: React.CSSProperties;
    isScrolling?: boolean;
}
const MUITableRowWrapper: FC<iProps> = (
    {
        children,
        head = false,
        index = 0,
        style,
        isScrolling = false,
    }
) => {
    const tableClasses = useStyles();

    if (isScrolling) {
        return (
            <TableRow
                component="div"
                className={clsx(tableClasses.row, tableClasses.rowLoading)}
                style={style}
            >
                <span className={tableClasses.rowLoader}></span>
            </TableRow>
        );
    }

    return (
        <TableRow
            component="div"
            className={clsx(tableClasses.row, head && tableClasses.headerRow, isEven(index) && tableClasses.rowHighlight)}
            style={style}
        >
            {children}
        </TableRow>
    );
};

export default MUITableRowWrapper;
