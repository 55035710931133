import { useCallback, useEffect, useState } from "react";
import useFetch from "use-http";
import { getApiLink } from "../apiConfig";
import { iAdminRibbon__API } from "../apiSchema";
import API from "../enums/API";
import iRibbon from "../models/iRibbon";

const useGetRibbons = () => {
    const { get, response, post, abort, loading = true } = useFetch<iAdminRibbon__API[]>();
    const ribbonOrders = useFetch();
    const {get: getRibbonIds} = useFetch<number[]>();

    const [data, setData] = useState<iRibbon[]>([]);

    const getRibbons = useCallback(async () => {
        const promises: [Promise<iAdminRibbon__API[]>, Promise<number[]>] = [
            get(getApiLink(API.GET_RIBBONS)),
            getRibbonIds(getApiLink(API.RIBBONS_ORDERED_IDS)),
        ];

        const [ribbons, ids] = await Promise.all(promises);

        if (ribbons && ids) {
            const data = ribbons.map((el: iAdminRibbon__API) => {
                return {
                    ...el,
                    title: el.name ?? "",
                };
            });

            const sortedByIds = data.sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id));

            setData(sortedByIds);
        }
    }, [get, getRibbonIds]);

    const updateOrders = useCallback(
        async (ids: number[], cb: () => void = () => {}) => {
            await ribbonOrders.post(getApiLink(API.RIBBONS_ORDERED_IDS), ids);

            if (ribbonOrders.response.ok) {
                getRibbons();
                cb();
            }
        },
        [ribbonOrders, getRibbons],
    );

    const abortUpdateOrders = useCallback(() => ribbonOrders.abort(), [ribbonOrders]);


    useEffect(() => {
        getRibbons();
    }, [getRibbons]);

    useEffect(() => {
        return () => {
            abort();
            ribbonOrders.abort();
        }
    }, [abort, ribbonOrders.abort]);

    return {
        data,
        loading,
        post,
        getRibbons,
        response,
        updateOrders,
        isOrdersUpdating: ribbonOrders.loading,
        abortUpdateOrders,
    }
}

export default useGetRibbons;