import { CircularProgress } from "@material-ui/core";
import Fade from '@material-ui/core/Fade';
import clsx from "clsx";
import { useStyles } from "./Loader.styles";

interface iProps {
    shown: boolean;
    fixedOverlay?: boolean;
    centered?: boolean;
}

const Loader = ({ shown, fixedOverlay, centered }: iProps) => {
    const classes = useStyles();

    return (
        <Fade in={shown} unmountOnExit>
            <div className={clsx(classes.loader, fixedOverlay && classes.fixedOverlay)}>
                <CircularProgress className={clsx(fixedOverlay && classes.Spinner, centered && classes.SpinnerCentered)} />
            </div>
        </Fade>
    );
};


export default Loader;
