import { fade } from "@material-ui/core";
import { addStyles } from "../../core/addStyles";

const useStyles = addStyles(theme => ({
    droparea: {
        position: "relative",
        outline: "none",

        "& .MuiDropzonePreviewList-removeButton": {
            zIndex: 100,
            opacity: 1,
        },
    },
    dropzone: {
        padding: theme.spacing(2),
        position: "relative",
        outline: "none",
        backgroundColor: "transparent",
        zIndex: 99,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&:hover": {
            borderColor: fade(theme.palette.primary.dark, 0.5),
        },
    },
    dropzoneActive: {
        "&.MuiDropzoneArea-active": {
            backgroundColor: "white",
        },
    },
    dropzoneError: {
        borderColor: fade(theme.palette.error.main, 1),
    },
    preview: {
        maxWidth: "100%",
        flexBasis: "100%",
        height: "100%",
        zIndex: "initial",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        "& > img": {
            width: "100%",
            height: "100%",
            objectFit: "contain",
        },
    },
    previewContainer: {
        maxWidth: `calc(100% - ${theme.spacing(1)}px)`,
        flexBasis: "100%",
        position: "absolute",
        left: theme.spacing(1),
        top: theme.spacing(1),
        height: `calc(100% - ${theme.spacing(1)}px)`,
    },
    text: {
        fontSize: "1rem",
        marginBottom: 0,
        marginTop: 0,
    },
    textActive: {
        opacity: 0,

        "& + svg": {
            opacity: 0,
        },
    },
    dropareaActive: {},
    loadingContainer: {
        minHeight: 250,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        zIndex: 10,
        background: `rgba(255,255,255,0.75)`,
    },
}));

export { useStyles };
