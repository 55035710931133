import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { iOptionValue } from "./Form.helpers";
import clsx from "clsx";
import "./HighlightValue.styles.scss";

const HighlightValue = ({ option, inputValue }: { option: iOptionValue; inputValue: string }) => {
    const matches = match(option.value, inputValue);
    const parts = parse(option.value, matches);

    return (
        <div>
            {parts.map((part, index) => {
                return (
                    <span
                        key={index}
                        className={clsx(part.highlight && "HighlightValue")}
                    >
                        {part.text}
                    </span>
                );
            })}
        </div>
    );
};

export default HighlightValue;