import { iMac } from "../../core/models/iMac";
import { useCallback, useMemo } from "react";
import { ColumnIconType } from "../../components/MUITable/useTableSort";
import { iTableConfigItem } from "../../components/MUITable/MUITable";
import { highlightValue } from "../../components/MUITable/MUITable.helpers";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { transformDate } from "../../core/helpers";
import ActionButtons, { ActionButtonTypeEnum } from "../../components/ActionButtons/ActionButtons";
import RoutesEnum from "../../core/enums/RoutesEnum";
import EditIcon from "@material-ui/icons/Edit";

export function useTableConfig(
    data: iMac[],
    searchQuery: string,
    sortingField: keyof iMac | null,
    sortingDirection: 'asc' | 'desc',
    handleSort: (fieldName: keyof iMac) => () => void,
    getColumnIcon: ColumnIconType
): iTableConfigItem[] {
    const columnIcon = useCallback(
        (fieldName: keyof iMac) => getColumnIcon(fieldName, sortingField, sortingDirection),
        [getColumnIcon, sortingDirection, sortingField]
    );

    return useMemo((): iTableConfigItem[] => [
        {
            title: 'ID',
            width: 70,
            Value: ({ index }) => <>{data[index].id}</>,
        },
        {
            icon: columnIcon('title'),
            isActive: sortingField === 'title',
            title: 'Title',
            Value: ({ index }) => <>{highlightValue(data[index].title, searchQuery)}</>,
            handleHeaderClick: handleSort("title"),
        },
        {
            icon: columnIcon('hasImage'),
            isActive: sortingField === 'hasImage',
            title: 'Image',
            width: 70,
            Value: ({ index }) => (
                <>
                    {data[index].hasImage ? <CheckIcon style={{ fill: 'green'}}/> : <CloseIcon style={{ fill: 'red'}}/>}
                </>
            ),
            handleHeaderClick: handleSort("hasImage"),
        },
        {
            icon: columnIcon('createDate'),
            isActive: sortingField === 'createDate',
            title: 'Created Date',
            width: 150,
            Value: ({ index }) => <>{transformDate(data[index].createDate)}</>,
            handleHeaderClick: handleSort("createDate"),
        },
        {
            icon: columnIcon('updateDate'),
            isActive: sortingField === 'updateDate',
            title: 'Updated Date',
            width: 150,
            Value: ({ index }) => <>{transformDate(data[index].updateDate)}</>,
            handleHeaderClick: handleSort("updateDate"),
        },
        {
            title: '',
            width: 150,
            Value: ({ index }) => {
                const item = data[index];

                return (
                    <ActionButtons config={[
                        {
                            type: ActionButtonTypeEnum.LinkIconButton,
                            ariaLabel: 'Edit',
                            link: RoutesEnum.MACsEdit.replace(':id', `${item.id}`),
                            Icon: EditIcon,
                        }
                    ]}/>
                )
            },
        },
    ], [columnIcon, handleSort, data, sortingField, searchQuery])
}