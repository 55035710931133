import { useCallback, useEffect, useState } from "react";
import useFetch from "use-http";
import { getApiLink } from "../apiConfig";
import API from "../enums/API";

const useGetRibbon = () => {
    const { get, response, post, abort, loading = true } = useFetch<number[]>();
    const [data, setData] = useState<number[]>([]);

    const getData = useCallback(async () => {
        const publicationIds = await get(getApiLink(API.GET_FEATURED));

        if (response.ok && publicationIds.length) {
            setData(publicationIds);
        }
    }, [get, response]);

    useEffect(() => {
        getData();
    }, [getData]);

    useEffect(() => {
        return () => {
            abort();
        }
    }, [abort]);

    return { data, updateData: setData, loading, post, response }
}

export default useGetRibbon;