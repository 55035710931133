import { Alert, Color } from "@material-ui/lab";
import "./NotificationsItem.styles.scss";

export type NotificationStatus = "info" | "success" | "error" | "warning";

export interface iNotification {
    message: React.ReactNode;
    status: Color;
    icon: React.ReactNode;
}

const NotificationsItem: React.FC<{
    notify: iNotification;
}> = ({ notify }) => {
    const { status, message } = notify;

    return (
        <Alert severity={status} className="NotificationsItem">
            {message}
        </Alert>
    );
};

export default NotificationsItem;
