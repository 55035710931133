import {
    Backdrop,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fade,
    Modal,
} from "@material-ui/core";
import { MODAL_TOGGLE_TIME } from "../../core/constants";
import { useStyles } from "./ConfirmPopup.styles";

interface iProps {
    shown: boolean;
    title: string;
    message?: string;
    submitButtonLabel?: string;
    closeModal: () => void;
    onConfirm: () => void;
}

const ConfirmPopup = ({
    shown,
    title,
    message,
    closeModal,
    onConfirm,
    submitButtonLabel = "Ok",
}: iProps) => {
    const classes = useStyles();

    const handleConfirm = () => {
        onConfirm();
        closeModal();
    };

    return (
        <Modal
            className={classes.modal}
            open={shown}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            disableBackdropClick
            BackdropProps={{
                timeout: MODAL_TOGGLE_TIME,
            }}
        >
            <Fade in={shown} unmountOnExit>
                <div className={classes.paper}>
                    <DialogTitle>{title}</DialogTitle>

                    <DialogContent>
                        <DialogContentText>{message}</DialogContentText>
                    </DialogContent>

                    <div className={classes.footer}>
                        <DialogActions>
                            <Button onClick={closeModal} color="default">
                                Cancel
                            </Button>
                            <Button onClick={handleConfirm} color="primary" autoFocus>
                                {submitButtonLabel}
                            </Button>
                        </DialogActions>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default ConfirmPopup;
