import useFetch from "use-http";
import { getApiLink } from "../apiConfig";
import API from "../enums/API";
import { ImageType } from "../apiSchema";

const useUploadImage = () => {
    const { post, response, loading } = useFetch(getApiLink(API.UPLOAD_IMAGE));

    const uploadImage = async (files: File[], imageType = ImageType.NUMBER_0) => {
        if (!files.length) return "";
        const currentFile = files[0];
        const filesData = new FormData();

        filesData.append("File", currentFile);
        // TODO: add enum for ImageType (0 - large, 1 - thumbnail) and pass to args type upload image
        filesData.append("ImageType", `${imageType}`);

        const fileUrl = await post(filesData);
        return fileUrl;
    };

    return { uploadImage, response, loading }
}

export default useUploadImage;