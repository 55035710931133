import { Grid } from "@material-ui/core";
import PageTitle from "../PageTitle/PageTitle";
import { useStyles } from "./Page.styles";

interface iProps {
    title: string;
}

const PageHeader: React.FC<iProps> = ({ title, children }) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.header}>
            <Grid item xs={7}>
                <PageTitle title={title} />
            </Grid>

            <Grid item xs={5} className={classes.actions}>
                {children}
            </Grid>
        </Grid>
    );
};

export default PageHeader;
