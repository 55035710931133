import React, { useCallback } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { RenderVerticalThumb } from "../Scrollbar";



const CustomScrollbarsVirtualList: React.FC<any> = ({
    onScroll,
    forwardedRef,
    style,
    children,
}) => {
    const refSetter = useCallback(
        scrollbarsRef => {
            if (scrollbarsRef) {
                forwardedRef(scrollbarsRef.view);
            } else {
                forwardedRef(null);
            }
        },
        [forwardedRef],
    );

    return (
        <Scrollbars
            renderThumbVertical={RenderVerticalThumb}
            renderTrackHorizontal={() => <div style={{ display: "none" }}></div>}
            ref={refSetter}
            style={{
                ...style,
                overflow: "hidden",
            }}
            onScroll={onScroll}
        >
            {children}
        </Scrollbars>
    );
};

export default React.forwardRef((props, ref) => (
    <CustomScrollbarsVirtualList {...props} forwardedRef={ref} />
));
