import React from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { getOptionLabel, filterOptions, iOptionValue } from "./Form.helpers";
import { VariableSizeList, ListChildComponentProps } from "react-window";
import HighlightValue from "./HighlightValue";

export interface iAutocompleteOption {
    id: number;
    value: any;
    inputValue?: string;
}

interface iProps {
    options: iAutocompleteOption[];
    value: iOptionValue[];
    label?: string;
    isDisabled?: boolean;
    onChange: (value: any) => void;
    creatable?: boolean;
    blurOnSelect?: boolean;
    renderOption?: (option: iAutocompleteOption, inputValue: string) => void;
    optionHeight?: number;
}
const LISTBOX_PADDING = 8;

function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: (style.top as number) + LISTBOX_PADDING,
        },
    });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

const ListboxComponent = React.forwardRef<HTMLDivElement, any>(function ListboxComponent(props, ref) {
    const { children, optionHeight,  ...other } = props;
    const itemData = React.Children.toArray(children);
    const itemCount = itemData.length;
    const itemSize = optionHeight || 40;

    const getChildSize = () => itemSize;

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="div"
                    itemSize={() => itemSize}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

const FormAutocomplete: React.FC<iProps> = ({
    options,
    value,
    isDisabled,
    onChange,
    label = "",
    creatable = false,
    blurOnSelect = false,
    renderOption,
    optionHeight
}) => {
    const handleChange = (value: iAutocompleteOption[]) => {
        onChange(value);
    };

    const renderAutocompleOption = (option: iAutocompleteOption, inputValue: string) => {
        if ( renderOption ) {
            return renderOption(option, inputValue);
        } else {
            return HighlightValue({ option, inputValue });
        }
    }

    return (
        <Autocomplete
            options={options}
            value={value}
            renderInput={params => (
                <TextField {...params} variant="outlined" label={label} placeholder="" />
            )}
            getOptionLabel={option => getOptionLabel(option)}
            filterOptions={(options, params) => filterOptions(options, params, creatable)}
            renderOption={(option, { inputValue }) => renderAutocompleOption(option, inputValue) as any}
            filterSelectedOptions
            freeSolo={creatable}
            multiple
            blurOnSelect={blurOnSelect}
            selectOnFocus
            clearOnBlur
            ListboxComponent={listboxProps => (
                <ListboxComponent
                    {...listboxProps}
                    optionHeight={optionHeight}
                />
              )}
            autoHighlight
            disabled={isDisabled}
            onChange={(_, value) => handleChange(value as iAutocompleteOption[])}
        />
    );
};

export default FormAutocomplete;
