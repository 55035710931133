import React, { FC, useCallback, useMemo, useState } from "react";
import RoutesEnum from "../../core/enums/RoutesEnum";
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router";
import useConfirmPopup from "../../hooks/useConfirmPopup";
import { filterDataBySearch } from "../../core/helpers";
import { Page, PageContent, PageHeader } from "../../components/PageLayout";
import HeaderActions from "../../components/HeaderActions/HeaderActions";
import ConfirmPopup from "../../components/ConfirmPopup/ConfirmPopup";
import MUITable, { iTableConfigItem } from "../../components/MUITable/MUITable";
import ActionButtons, { ActionButtonTypeEnum } from "../../components/ActionButtons/ActionButtons";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { highlightValue } from "../../components/MUITable/MUITable.helpers";
import useTags from "../../core/api/useTags";

export const Tags: FC = () => {
    let history = useHistory();
    const {
        data,
        isLoading,
        removeTag,
    } = useTags();
    const [searchQuery, setSearchQuery] = useState("");
    const removeConfirm = useConfirmPopup();
    const filteredData = useMemo(() => filterDataBySearch(data, searchQuery),[data, searchQuery]);

    const showRemoveConfirm = useCallback(
        (id: number, title: string) => {
            removeConfirm.show(id, title);
        },
        [removeConfirm],
    );

    const handleRemove = async () => {
        removeTag(removeConfirm.itemId);
    };

    const tableConfig: iTableConfigItem[] = useMemo(() => [
        { title: 'ID', width: 100, Value: ({ index }) => <>{filteredData[index].id}</> },
        { title: 'Tag title', Value: ({ index }) => highlightValue(filteredData[index].title, searchQuery) },
        { title: 'Publications count', width: 200, Value: ({ index }) => <>{filteredData[index].publicationsIds.length}</> },
        {
            title: '',
            width: 100,
            Value: ({ index }) => {
                const item = filteredData[index];

                return (
                    <ActionButtons config={[
                        {
                            type: ActionButtonTypeEnum.LinkIconButton,
                            ariaLabel: 'Edit',
                            link: RoutesEnum.TagEdit.replace(':id', `${item.id}`),
                            Icon: EditIcon,
                        },
                        {
                            ariaLabel: 'Delete',
                            Icon: DeleteIcon,
                            color: 'secondary',
                            onClick: () => showRemoveConfirm(item.id, item.title),
                        },
                    ]}/>
                )
            },
        },
    ], [filteredData, searchQuery, showRemoveConfirm]);

    return (
        <Page>
            <PageHeader title="Content Tags">
                <HeaderActions
                    searchQuery={searchQuery}
                    searchLabel="Search Tags"
                    setSearchQuery={(value: string) => setSearchQuery(value)}
                    submitIcon={<AddIcon />}
                    submitText="Add tag"
                    onSubmit={() => history.push(RoutesEnum.TagAdd)}
                />
            </PageHeader>

            <PageContent>
                <MUITable
                    isLoading={isLoading}
                    config={tableConfig}
                    rowsCount={filteredData.length}
                />
                <ConfirmPopup
                    title={`Delete content tag "${removeConfirm.itemTitle}"`}
                    message="Are you sure to delete this item?"
                    shown={removeConfirm.shown}
                    onConfirm={handleRemove}
                    closeModal={removeConfirm.hide}
                    submitButtonLabel="Delete"
                />
            </PageContent>
        </Page>
    );
};
