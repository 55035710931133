import { Backdrop, Fade, Modal } from "@material-ui/core";
import { MODAL_TOGGLE_TIME } from "../../core/constants";
import { useStyles } from "./Popup.styles";

interface iProps {
    shown: boolean;
    closeModal: () => void;
    children: React.ReactNode
}

const Popup = ({ shown, closeModal, children }: iProps) => {
    const classes = useStyles();
    return (
        <Modal
            className={classes.modal}
            open={shown}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            disableBackdropClick
            BackdropProps={{
                timeout: MODAL_TOGGLE_TIME,
            }}
        >
            <Fade in={shown} unmountOnExit>
                <div className={classes.paper}>
                    {children}
                </div>
            </Fade>
        </Modal>
    );
};

export default Popup;
