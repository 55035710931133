import { useEffect, useState } from "react";
import useFetch from "use-http";
import { apiOptions, getApiLink, getApiLinkWithDomain } from "../apiConfig";
import { iSnapUser__API } from "../apiSchema";
import API from "../enums/API";

const useAdminTestFlight = () => {
    const { response, loading } = useFetch<any>(
        `${getApiLinkWithDomain(API.TEST_FLIGHT)}`, apiOptions, []
    );

    const [hasAccess, setHasAccess] = useState(true);
    const [isLoaded, setIsLoaded] = useState(true);

    useEffect(() => {
        if ( loading ) return;

        setIsLoaded(true);
        setHasAccess(response.ok);
    }, [response.ok, loading]);

    return { hasAccess, isLoaded };
};

export default useAdminTestFlight;
