import { iAutocompleteOption } from "./FormAutocomplete";
import { createFilterOptions, FilterOptionsState } from "@material-ui/lab";
export const getOptionLabel = (option: iAutocompleteOption): string => {
    if (typeof option === "string") {
        return option;
    }

    if (option.inputValue) {
        return option.inputValue;
    }

    return option.value;
};
const filter = createFilterOptions<iAutocompleteOption>();
export const filterOptions = (
    options: iAutocompleteOption[],
    params: FilterOptionsState<iAutocompleteOption>,
    creatable: boolean = false,
    ): iAutocompleteOption[] => {
    const filtered = filter(options, params);
    if (creatable && !filtered.length && params.inputValue.trim() !== "") {
        filtered.push({
            inputValue: params.inputValue.trimLeft().trim(),
            value: `Add "${params.inputValue}"`,
            id: 0,
        });
    }
    return filtered;
};
export interface iOptionValue {
    id: number;
    value: string;
}
