import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { deepEqual, prepareForSorting } from "../../core/helpers";
import { IconOrder, IconOrderArrows, IconOrderReverse } from "../../icons";

export type ColumnIconType = <T>(
    fieldName: keyof T,
    sortingField: keyof T | null,
    sortingDirection: string,
) => ReactNode;

const SKIPPING_VALUES: Array<any> = [
    null,
];

function useTableSort<T>(data: T[] = [], defaultSortingField?: keyof T, defaultSortingDirection?: 'asc' | 'desc') {
    const [sortingDirection, setSortingDirection] = useState<"asc" | "desc">(defaultSortingDirection || "asc");
    const [sortingField, setSortingField] = useState<keyof T | null>(defaultSortingField || null);
    const sortingFactor = useMemo(() => (sortingDirection === "asc" ? 1 : -1), [sortingDirection]);
    const [sortedData, setSortedData] = useState(data);

    const toggleSortingDirection = useCallback(
        () => setSortingDirection(sortingDirection === "asc" ? "desc" : "asc"),
        [sortingDirection],
    );

    const getColumnIcon: ColumnIconType = useCallback(
        (fieldName, sortingField, sortingDirection) => {
            if (sortingField === fieldName) {
                return sortingDirection === "asc" ? <IconOrderReverse /> : <IconOrder />;
            } else {
                return <IconOrderArrows />;
            }
        },
        [],
    );

    const handleSort = useCallback(
        (fieldName: keyof T) => () => {
            return sortingField === fieldName
                ? toggleSortingDirection()
                : setSortingField(fieldName);
        },
        [sortingField, toggleSortingDirection],
    );

    const handleResetSorting = useCallback(() => {
        setSortingDirection("asc");
        setSortingField(null);
    }, []);

    const sortData = useCallback(
        function <T>(data: T[], sortingField: keyof T | null) {
            const temp = [...data];
            if (sortingField) {
                const field = sortingField;
                temp.sort((a, b) => {
                    const aValue = prepareForSorting(a[field]);
                    const bValue = prepareForSorting(b[field]);
                    if (SKIPPING_VALUES.includes(aValue)) return 1;
                    else if (SKIPPING_VALUES.includes(bValue)) return -1;
                    else return sortingFactor * (aValue <= bValue ? -1 : 1);
                });
            }
            return temp;
        },
        [sortingFactor],
    );

    useEffect(() => {
        const newSortedData = sortData(data, sortingField);
        if ( deepEqual(sortedData, newSortedData) ) return;
        setSortedData(newSortedData);
    }, [data, sortData, sortingField, sortedData]);

    useEffect(() => {
        if ( defaultSortingField ) {
            setSortingField(defaultSortingField);
        }
    }, [defaultSortingField]);

    return {
        handleSort,
        sortingFactor,
        sortingDirection,
        sortingField,
        getColumnIcon,
        handleResetSorting,
        setSortingDirection,
        sortData,
        sortedData
    };
}

export default useTableSort;
