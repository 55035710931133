import { Grid } from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./Page.styles";

const PageContent: React.FC = ({ children }) => {
    const classes = useStyles();

    return (
        <Grid item xs={12} className={clsx(classes.content)}>
            {children}
        </Grid>
    );
};

export default PageContent;
