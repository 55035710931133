import {
    iFormConfigItem,
    isNotEmpty,
    isNumber,
    isPositiveNumber,
    isNonNegativeNumber,
} from "../../../core/formValidation";

export interface iValidationData {
    title: string;
    numberOfPages: number;
    minutesToRead: number;
    pageFrom: number;
    pageTo: number;
}

export const validationConfig: iFormConfigItem<iValidationData>[] = [
    { field: "title", validationCheckers: [isNotEmpty] },
    { field: "numberOfPages", validationCheckers: [isNotEmpty, isNumber, isPositiveNumber] },
    { field: "minutesToRead", validationCheckers: [isNotEmpty, isNumber, isPositiveNumber] },
    { field: "pageFrom", validationCheckers: [isNotEmpty, isNumber, isNonNegativeNumber] },
    { field: "pageTo", validationCheckers: [isNotEmpty, isNumber, isNonNegativeNumber] },
];
