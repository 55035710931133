type Checker = (value: any) => boolean;

export interface iFormConfigItem<T> {
    field: keyof T;
    compareField?: keyof T;
    validationCheckers: Checker[];
    message?: string;
}

export function isNotEmpty(str: string | number) {
    return `${str}`.trim() !== '';
}

export function isNotEmptyArray(arr: any[]) {
    return arr.length > 0;
}

export function isEmail(email: string) {
    // eslint-disable-next-line no-useless-escape
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email.trim());
}

export function isUnique(value: boolean) {
    return value;
}
export function isNumber(value: any) {
    return !isNaN(parseFloat(value)) && isFinite(value);
}
export function isPositiveNumber(value: number) {
    return isNumber(value) && value > 0;
}

export function isNonNegativeNumber(value: number) {
    return isNumber(value) && value >= 0;
}



export function isInputValid<T>(data: T) {
    return function (config: iFormConfigItem<T>[]) {
        return function (inputField: keyof T) {
            let isValid = true;
            const input = config.find((input) => input.field === inputField);

            if (input) {
                input.validationCheckers.forEach((checker) => {
                    isValid = isValid && checker(data[input.field]);
                });

                if (input.compareField) {
                    isValid =
                        isValid &&
                        data[input.field as keyof T] === data[input.compareField];
                }
            }

            return isValid;
        };
    };
}

export function getInputErrors<T>(data: T) {
    return function (config: iFormConfigItem<T>[]) {
        return function (inputField: keyof T) {
            const errors: string[] = [];
            const input = config.find((input) => input.field === inputField);

            if (input) {
                input.validationCheckers.forEach((checker) => {
                    if (!checker(data[input.field])) {
                        errors.push(input.message || getDefaultCheckersErrorMessage(checker));
                    }
                });

                if (input.compareField) {
                    if (data[input.field] !== data[input.compareField]) {
                        errors.push('is not equal');
                    }
                }
            }
            return errors;
        };
    };
}


const getDefaultCheckersErrorMessage = (checker: Checker) => {
    let message: string;

    switch (checker) {
        case isNotEmpty: {
            message = 'should not be empty';
            break;
        }
        case isNotEmptyArray: {
            message = 'should not be empty';
            break;
        }
        case isEmail: {
            message = 'incorrect email';
            break;
        }
        case isNumber: {
            message = "should be a number";
            break;
        }
        case isPositiveNumber: {
            message = 'should be more than zero';
            break;
        }
        case isNonNegativeNumber: {
            message = 'should not be negative number';
            break;
        }
        case isUnique: {
            message = 'already exist';
            break;
        }
        default: {
            message = 'has error';
        }
    }
    return message;
};

export function isDataValid<T>(data: T) {
    return function (config: iFormConfigItem<T>[]) {
        let isValid = true;

        Object.keys(data).forEach((inputField) => {
            isValid = isValid && isInputValid(data)(config)(inputField as keyof T);
        });

        return isValid;
    };
}
