import { addStyles } from "../../core/addStyles";

const useStyles = addStyles(theme => ({
    error: {
        color: theme.palette.error.main,
        fontSize: 12,
        lineHeight: 1.4,

        "& > div": {
            marginTop: 5,
            marginLeft: 14,
            marginRight: 14
        }
    },
}));

export { useStyles }