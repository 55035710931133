import React, { FC, useCallback, useMemo, useState } from "react";
import RoutesEnum from "../../core/enums/RoutesEnum";
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router";
import useConfirmPopup from "../../hooks/useConfirmPopup";
import { filterDataBySearch } from "../../core/helpers";
import { getApiLink } from "../../core/apiConfig";
import API from "../../core/enums/API";
import { Page, PageContent, PageHeader } from "../../components/PageLayout";
import HeaderActions from "../../components/HeaderActions/HeaderActions";
import ConfirmPopup from "../../components/ConfirmPopup/ConfirmPopup";
import useGetRibbons from "../../core/api/useGetRibbons";
import MUITable, { iTableConfigItem } from "../../components/MUITable/MUITable";
import ActionButtons, { ActionButtonTypeEnum } from "../../components/ActionButtons/ActionButtons";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { highlightValue } from "../../components/MUITable/MUITable.helpers";
import SortableTable from "../../components/SortableTable/SortableTable";
import iRibbon from "../../core/models/iRibbon";
import { ManageOrders } from "./ManageOrders/ManageOrders";
import { Button } from "@material-ui/core";
import { useStyles } from "./ManageOrders/ManageOrders.styles";
import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing';
type TableConfigType = (tableData: iRibbon[]) => iTableConfigItem[];

const Ribbons: FC = () => {
    let history = useHistory();
    const styles = useStyles()
    const {
        data,
        loading,
        post,
        response,
        getRibbons,
        isOrdersUpdating,
        updateOrders,
        abortUpdateOrders,
    } = useGetRibbons();
    const [searchQuery, setSearchQuery] = useState("");
    const removeConfirm = useConfirmPopup();
    const filteredData = useMemo(() => filterDataBySearch(data, searchQuery),[data, searchQuery]);
    const [isManageOrdersOpen, setIsManageOrdersOpen] = useState(false);
    const [ribbonIds, setRibbonIds] = useState<number[]>([]);

    const showRemoveConfirm = useCallback(
        (id: number, title: string) => {
            removeConfirm.show(id, title);
        },
        [removeConfirm],
    );

    const handleRemoveRibbon = async () => {
        await post(getApiLink(API.DELETE_RIBBON, { id: removeConfirm.itemId }));

        if (response.ok) {
            getRibbons();
        }
    };

    const tableConfig: iTableConfigItem[] = useMemo(() => [
        { title: 'ID', width: 100, Value: ({ index }) => <>{filteredData[index].id}</> },
        { title: 'Title', Value: ({ index }) => highlightValue(filteredData[index].title, searchQuery) },
        { title: 'Publications count', width: 200, Value: ({ index }) => <>{filteredData[index].publicationIds.length}</> },
        {
            title: '',
            width: 100,
            Value: ({ index }) => {
                const item = filteredData[index];

                return (
                    <ActionButtons config={[
                        {
                            type: ActionButtonTypeEnum.LinkIconButton,
                            ariaLabel: 'Edit',
                            link: RoutesEnum.RibbonEdit.replace(':id', `${item.id}`),
                            Icon: EditIcon,
                        },
                        {
                            ariaLabel: 'Delete',
                            Icon: DeleteIcon,
                            color: 'secondary',
                            onClick: () => showRemoveConfirm(item.id, item.title),
                        },
                    ]}/>
                )
            },
        },
    ], [filteredData, searchQuery, showRemoveConfirm]);

    const manageOrdersTableConfig: TableConfigType = useMemo(() => (tableData) => [
        { title: 'ID', width: 100, Value: ({ index }) => <>{tableData[index].id}</> },
        { title: 'Title', Value: ({ index }) => <>{tableData[index].title}</> },
        { title: 'Publications count', width: 200, Value: ({ index }) => <>{tableData[index].publicationIds.length}</> },
    ], []);

    const onDragEnd = useCallback((data: iRibbon[]) => {
        const ids = data.map(ribbon => ribbon.id);
        setRibbonIds(ids);
    }, []);

    const handleUpdateOrders = useCallback(() => {
        updateOrders(ribbonIds, () => setIsManageOrdersOpen(false));
    }, [ribbonIds, updateOrders]);

    return (
        <Page>
            <PageHeader title="Content Ribbons">
                <Button
                    className={styles.orderButton}
                    variant="outlined"
                    color="primary"
                    onClick={() => setIsManageOrdersOpen(true)}
                    startIcon={<FormatLineSpacingIcon />}
                >
                    Reorder ribbons
                </Button>

                <HeaderActions
                    searchQuery={searchQuery}
                    searchLabel="Search ribbons"
                    setSearchQuery={(value: string) => setSearchQuery(value)}
                    submitIcon={<AddIcon />}
                    submitText="Add ribbon"
                    onSubmit={() => history.push(RoutesEnum.RibbonAdd)}
                />

            </PageHeader>

            <PageContent>
                <MUITable
                    isLoading={loading}
                    config={tableConfig}
                    rowsCount={filteredData.length}
                />
                <ConfirmPopup
                    title={`Delete content ribbon "${removeConfirm.itemTitle}"`}
                    message="Are you sure to delete this item?"
                    shown={removeConfirm.shown}
                    onConfirm={handleRemoveRibbon}
                    closeModal={removeConfirm.hide}
                    submitButtonLabel="Delete"
                />

                <ManageOrders
                    isOpen={isManageOrdersOpen}
                    onClose={() =>{ setIsManageOrdersOpen(false); abortUpdateOrders()}}
                    onSubmit={handleUpdateOrders}
                    isLoading={isOrdersUpdating}
                >
                    <SortableTable
                        id="RibbonsTable"
                        isLoading={loading || isOrdersUpdating}
                        config={manageOrdersTableConfig}
                        rowsCount={data.length}
                        data={data}
                        onDragEnd={onDragEnd}
                        isDraggingDisabled={false}
                    />
                </ManageOrders>
            </PageContent>
        </Page>
    );
};

export default Ribbons;