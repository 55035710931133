import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import clsx from "clsx";
import { useStyles } from "./Form.styles";

interface iProps {
    label: string;
    onChange: (value: any) => void;
    onError?: (value: any) => void;
    value: any;
    hasError?: boolean;
    required?: boolean;
}

const FormDatepicker: React.FC<iProps> = ({
    label,
    onChange,
    onError = () => {},
    value,
    hasError,
    required = false,
    children,
}) => {
    const classes = useStyles();

    const handleChange = (value: string) => {
        onChange(value);
    };

    return (
        <div className={clsx(classes.formInput)}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    label={label}
                    format="MM/dd/yyyy"
                    variant="dialog"
                    inputVariant="outlined"
                    fullWidth
                    className={classes.datepicker}
                    required={required}
                    value={value ? new Date(value) : null}
                    onChange={date => handleChange(date?.toString() || "")}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    error={hasError}
                    onError={error => onError(error)}
                />
            </MuiPickersUtilsProvider>

            {children}
        </div>
    );
};

export default FormDatepicker;
