import { iFormConfigItem, isNotEmpty } from "../../../core/formValidation";

export interface iValidationData {
    bigImageName: string;
}

export const validationConfig: iFormConfigItem<iValidationData>[] = [
    {
        field: "bigImageName",
        validationCheckers: [isNotEmpty],
        message: "Should be attached image",
    },
]