import { iChapterAdmin__API } from "../apiSchema";

export interface iChapter extends iChapterAdmin__API {
    bigImageName: string;
}

export class Chapter implements iChapter {
    files = [];
    id = 0;
    title = "";
    description = "";
    bigImageName = "";
    featuredContentImageName = '';
    numberOfPages = 0;
    minutesToRead = 0;
    pageFrom = 0;
    pageTo = 0;
    tagsIds = [];
    publicationId = 0;
}
