import React, { FC, useMemo } from "react";
import FormAutocomplete, { iAutocompleteOption } from "../../../components/Form/FormAutocomplete";
import HighlightValue from "../../../components/Form/HighlightValue";
import { useStyles } from './SelectPublications.styles';
import useGetPublications from "../../../core/api/useGetPublications";
import { iShortPublication } from "../../../core/models/iPublication";

const SelectPublications: FC<{
    publications: iShortPublication[];
    publicationsIds: number[];
    onChange: (publicationsIds: number[]) => void;
    isDisabled: boolean;
}> = (
    {
        publicationsIds,
        publications,
        onChange,
        isDisabled,
    }
) => {
    const classes = useStyles();
    const publicationsToOptions: iAutocompleteOption[] = useMemo(() => {
        const filtered = publications.filter(item => !publicationsIds.includes(item.id));
        return filtered.map(({ id, title, titleCategoryName, createDate }) => ({
            id,
            value: `${title} / ${titleCategoryName} / ${createDate}`,
        }));
    }, [publicationsIds, publications]);

    const handleChangeAutocomplete = (selectedPublications: iAutocompleteOption[]) => {
        if (!selectedPublications.length) return;
        const { id } = selectedPublications[0];
        if (id) {
            onChange([...publicationsIds, id]);
        }
    };

    const customRenderOption = (option: iAutocompleteOption, value: string) => {
        const words = option.value.split(" / ");
        const title = words[0];
        const category = words[1];
        const date = words[2];
        const highlightTitle = HighlightValue({ option: { ...option, value: title}, inputValue: value });
        const highlightCategory = HighlightValue({ option: { ...option, value: category}, inputValue: value });
        return (
            <div className={classes.option}>
                <div className={classes.optionTitle}>{highlightTitle}</div>
                <div className={classes.optionInfo}>
                    {date && <span className={classes.optionDate}>{date}</span>}
                    {category && <span className={classes.optionCategory}>{highlightCategory}</span>}
                </div>
            </div>
        );
    };

    return (
        <FormAutocomplete
            label="Publications"
            options={publicationsToOptions}
            value={[]}
            onChange={handleChangeAutocomplete}
            isDisabled={isDisabled}
            blurOnSelect
            renderOption={customRenderOption}
            optionHeight={50}
        />
    )
};

export default SelectPublications;