import { Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import clsx from "clsx";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { NavLink as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import AmpStoriesIcon from "@material-ui/icons/AmpStories";
import LocalActivityIcon from "@material-ui/icons/LocalActivity";
import LabelIcon from "@material-ui/icons/Label";
import { InsertChart } from '@material-ui/icons';
import { forwardRef, useMemo } from "react";
import RoutesEnum from "../../core/enums/RoutesEnum";
import useStyles from "../App/App.styles";

interface iListItemLinkProps {
    icon?: React.ReactElement;
    primary: string;
    to: string;
}

interface iSidebarProps {
    togglePanel: (value: boolean) => void;
    showPanel: boolean;
}

// TODO: apply styles to active Link
const ListItemLink = ({ icon, primary, to }: iListItemLinkProps) => {
    const { navIsActive } = useStyles();

    const renderLink = useMemo(
        () =>
            forwardRef<any, Omit<RouterLinkProps, "to">>((itemProps, ref) => (
                <RouterLink to={to} ref={ref} activeClassName={navIsActive} {...itemProps} />
            )),
        [navIsActive, to],
    );

    return (
        <li>
            <ListItem button component={renderLink}  >
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} />
            </ListItem>
        </li>
    );
};

const Sidebar = ({ showPanel, togglePanel }: iSidebarProps) => {
    const classes = useStyles();

    return (
        <Drawer
            variant="permanent"
            classes={{
                paper: clsx(classes.drawerPaper, !showPanel && classes.drawerPaperClose),
            }}
            open={showPanel}>
            <div className={classes.toolbarIcon}>
                <IconButton onClick={() => togglePanel(false)}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>

            <Divider />

            <List>
                <ListItemLink to={RoutesEnum.Publications} primary="Publications" icon={<LibraryBooksIcon />} />
                <ListItemLink to={RoutesEnum.Ribbons} primary="Content ribbons" icon={<AmpStoriesIcon />} />
                <ListItemLink to={RoutesEnum.FeaturedContent} primary="Featured Content" icon={<LocalActivityIcon />} />
                <ListItemLink to={RoutesEnum.MACs} primary="MACs" icon={<InsertChart />} />
                <ListItemLink to={RoutesEnum.Tags} primary="Content Tags" icon={<LabelIcon />} />
            </List>
        </Drawer>
    );
};

export default Sidebar;
