import { iFormConfigItem, isNotEmpty, isNotEmptyArray, isUnique } from "../../core/formValidation";

export interface iTagValidationData {
    title: string;
    publicationIds: number[];
    uniqueTitle: boolean;
}

export const tagValidationConfig: iFormConfigItem<iTagValidationData>[] = [
    { field: "title", validationCheckers: [isNotEmpty] },
    { field: "publicationIds", validationCheckers: [isNotEmptyArray] },
    { field: "uniqueTitle", validationCheckers: [isUnique] }
];