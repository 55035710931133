import { useCallback, useEffect, useState } from "react";
import useFetch from "use-http";
import { getApiLink } from "../core/apiConfig";
import API from "../core/enums/API";
import { ImageType } from "../core/apiSchema";

interface iFile {
    data: string | ArrayBuffer | null;
    file: File;
}

const DEFAULT_IMAGE_SIZE = 300;

const useGetFile = () => {
    const { get, response, abort } = useFetch();
    const [image, setImage] = useState<iFile[]>([]);
    const [file, setFile] = useState<iFile[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const getImage = useCallback(async (filename: string, imageType = ImageType.NUMBER_0) => {
        if ( !filename ) return;
        setIsLoading(true);
        await get(getApiLink(API.GET_IMAGE, { filename, size: DEFAULT_IMAGE_SIZE, imageType }));

        if ( response.ok ) {
            const blob = await response.blob();
            const file = new File([blob], filename, { type:blob.type });
            let readFile = new FileReader();
            readFile.readAsDataURL(blob);
            readFile.onloadend = () => {
                setImage([{
                    file,
                    data: readFile.result
                }])
                setIsLoading(false);
                setIsLoaded(true);
            }
        } else {
            setIsLoading(false);
            setIsLoaded(true);
        }
    }, [get, response]);

    const handleUpdateImage = useCallback((value: iFile[]) => {
        setImage(value);
    }, []);

    useEffect(() => {
        return () => {
            abort();
        }
    }, [abort]);

    return { isLoading, getImage, image, isLoaded, handleUpdateImage, file, setFile };
}

export default useGetFile;