import AddIcon from "@material-ui/icons/Add";
import React, { useMemo, useState, useCallback } from "react";
import Popup from "../Popup/Popup";
import ConfirmPopup from "../ConfirmPopup/ConfirmPopup";
import useGetChapters from "../../core/api/useGetChapters";
import { filterDataBySearch } from "../../core/helpers";
import ManageChapter from "./ManageChapter/ManageChapter";
import { getApiLink } from "../../core/apiConfig";
import useFetch from "use-http";
import API from "../../core/enums/API";
import useConfirmPopup from "../../hooks/useConfirmPopup";
import { iChapterAdmin__API } from "../../core/apiSchema";
import { Chapter, iChapter } from "../../core/models/iChapter";
import useNotification from "../../hooks/useNotifications";
import { Page, PageContent, PageHeader } from "../PageLayout";
import HeaderActions from "../HeaderActions/HeaderActions";
import MUITable, { iTableConfigItem } from "../MUITable/MUITable";
import ActionButtons from "../ActionButtons/ActionButtons";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { highlightValue } from "../MUITable/MUITable.helpers";

interface iProps {
    publicationId: number;
    isDisabled?: boolean;
}

const Chapters: React.FC<iProps> = ({ publicationId, isDisabled }) => {
    const emptyChapter = useMemo(() => new Chapter(), []);
    const { data, getData, loading, post, response } = useGetChapters(publicationId);
    const [shownChapterModal, setShownChapterModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [chapterId, setChapterId] = useState<null | number>(null);
    const updateChapter = useFetch();
    const removeConfirm = useConfirmPopup();
    const [selectedChapterData, setSelectedChapterData] = useState<iChapter>(emptyChapter);
    const { addNotification } = useNotification();

    const filteredData = useMemo(
        () => filterDataBySearch(data, searchQuery),
        [data, searchQuery],
    );

    const toggleChapterModal = useCallback(
        (id: number | null = null) => {
            setChapterId(id);
            setShownChapterModal(!shownChapterModal);
            setSelectedChapterData(data.find(item => item.id === id) || emptyChapter);
        },
        [shownChapterModal, data, emptyChapter],
    );

    const closeChapterModal = () => {
        toggleChapterModal(null);
        setChapterId(null);
    };

    const showRemoveConfirm = useCallback(
        (id: number, title: string) => {
            removeConfirm.show(id, title);
        },
        [removeConfirm],
    );

    const handleRemoveChapter = async () => {
        await post(getApiLink(API.DELETE_CHAPTER, { id: removeConfirm.itemId }));

        if (response.ok && publicationId) {
            getData(publicationId);
        }
    };

    const hadnleUpdateChapter = async (isUpdateChapter: boolean, data: iChapterAdmin__API) => {
        if (!publicationId) return;

        if (isUpdateChapter) {
            await updateChapter.post(getApiLink(API.EDIT_CHAPTER), data);
            if (updateChapter.response.ok) {
                addNotification({
                    message: <span>Chapter saved</span>,
                    status: "success",
                });
                if (publicationId) {
                    getData(publicationId);
                }
            } else {
                // TODO: show notification error
            }
        } else {
            await updateChapter.post(
                getApiLink(API.CREATE_CHAPTER),
                { ...data, publicationId }
            );
            if (updateChapter.response.ok) {
                addNotification({
                    message: <span>Chapter created</span>,
                    status: "success",
                });
                if (publicationId) {
                    getData(publicationId);
                }
            } else {
                // TODO: show notification error
            }
        }
        toggleChapterModal(null);
    };

    const tableConfig: iTableConfigItem[] = useMemo(() => [
        { title: 'Title', Value: ({ index }) => highlightValue(filteredData[index].title, searchQuery) },
        { title: 'Page from', width: 125, Value: ({ index }) => <>{filteredData[index].pageFrom}</> },
        { title: 'Page to', width: 125, Value: ({ index }) => <>{filteredData[index].pageTo}</> },
        {
            title: 'Actions',
            width: 100,
            Value: ({ index }) => {
                const item = filteredData[index];

                return (
                    <ActionButtons config={[
                        {
                            ariaLabel: 'Edit',
                            onClick: () => toggleChapterModal(item.id),
                            Icon: EditIcon,
                        },
                        {
                            ariaLabel: 'Delete',
                            Icon: DeleteIcon,
                            color: 'secondary',
                            onClick: () => showRemoveConfirm(item.id, item.title),
                        },
                    ]}/>
                )
            },
        },
    ], [filteredData, showRemoveConfirm, toggleChapterModal, searchQuery]);

    return (
        <Page>
            <PageHeader title="Chapters management">
                <HeaderActions
                    searchQuery={searchQuery}
                    setSearchQuery={(value: string) => setSearchQuery(value)}
                    searchLabel="Search chapters"
                    submitIcon={<AddIcon />}
                    submitText="Add chapter"
                    submitIsDisabled={isDisabled}
                    onSubmit={() => toggleChapterModal(null)}
                />
            </PageHeader>

            <PageContent>
                <MUITable isLoading={loading} config={tableConfig} rowsCount={filteredData.length}/>
            </PageContent>

            <ConfirmPopup
                title={`Delete chapter ${removeConfirm.itemTitle}`}
                message="Are you sure to delete this item?"
                shown={removeConfirm.shown}
                onConfirm={handleRemoveChapter}
                closeModal={removeConfirm.hide}
                submitButtonLabel="Delete"
            />

            <Popup shown={shownChapterModal} closeModal={toggleChapterModal}>
                <ManageChapter
                    closeModal={closeChapterModal}
                    onSubmit={hadnleUpdateChapter}
                    chapterId={chapterId}
                    data={selectedChapterData}
                />
            </Popup>
        </Page>
    );
};

export default Chapters;
