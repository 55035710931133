import { addStyles } from "../../../core/addStyles";

const useStyles = addStyles(theme => ({
    option: {
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
        top: 0,
        padding: "6px 16px",
        "&:hover": {
            backgroundColor: theme.palette.background.default,
        },
    },
    optionTitle: {
        fontSize: 16,
    },

    optionInfo: {
        fontSize: 14,
        lineHeight: 1,
        display: "flex",
    },
    optionDate: {
        color: theme.palette.grey[500],
        marginRight: theme.spacing(2),
        position: "relative",
        "&::before": {
            content: '""',
            display: "block",
            position: "absolute",
            right: -10,
            top: "50%",
            height: 4,
            width: 4,
            marginTop: -2,
            backgroundColor: theme.palette.grey[500],
            borderRadius: "50%",
        },
    },
    optionCategory: {
        color: theme.palette.grey[500],
    },
}));

export { useStyles };