import { useEffect } from "react";
import { useHistory } from "react-router";
import Chapters from "../../components/Chapters/Chapters";
import RoutesEnum from "../../core/enums/RoutesEnum";
import { useRouteMatch } from "react-router-dom";

const PageChapters: React.FC = () => {
    let history = useHistory();
    const match = useRouteMatch<{ id: string | undefined }>();
    const currentId = Number(match?.params?.id);

    useEffect(() => {
        if ( !currentId ) {
            history.push(RoutesEnum.Publications);
        }
    }, [currentId, history]);

    return <Chapters publicationId={currentId} />
}

export default PageChapters;