import { useCallback, useEffect, useState } from "react";

export interface iUseData<T> {
    data: T;
    isLoading: boolean;
    isSuccess: boolean | null;
    reFetch: () => void;
}

export function useData<ResT>(defaultData: ResT, fetchFn: (...reqData: any) => Promise<ResT>, ...reqData: any): iUseData<ResT> {
    const [isLoading, setIsLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState<boolean | null>(true);
    const [data, setData] = useState<ResT>(defaultData);

    const getData = useCallback(() => {
        setIsLoading(true);
        setIsSuccess(null);
        setData(defaultData);

        fetchFn(...reqData)
            .then((newData) => {
                setIsSuccess(true);
                setData(newData);
            })
            .catch(() => {
                setIsSuccess(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [defaultData, fetchFn, reqData, ]);

    useEffect(getData, [JSON.stringify(reqData)]);

    const reFetch = () => {
        getData();
    };

    return {
        isLoading,
        isSuccess,
        data,
        reFetch
    };
}
