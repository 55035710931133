import { iChapterAdmin__API, iFile__API, iPublicationAdmin__API, iTag__API } from "../apiSchema";
import FileTypes from "../enums/FileTypes";
import { transformDate } from "../helpers";

interface iFile {
    filename: string;
    type: FileTypes;
}

export interface iPublication {
    id: number;
    title: string;
    description: string;
    tagsIds: number[];
    numberOfPages: number;
    minutesToRead: number;
    bigImageName: string;
    featuredContentImageName?: string | null;
    files: iFile__API[];
    price: number | null;
    isPublished?: boolean;
    createDate: string;
    buttonCategoryId: number;
    titleCategoryId: number;
    chapters?: iChapterAdmin__API[] | null;
    subscriptionPackageIds: number[];
    filePDF: iFile | null;
    fileSamplePDF: iFile | null;
    filePPT: iFile | null;
    fileOperatorIdeaXLS: iFile | null;
    fileConsumerIdeaXLS: iFile | null;
}

export class Publication implements iPublication {
    filePDF = Publication.defaultData.filePDF;
    filePPT = Publication.defaultData.filePPT;
    fileOperatorIdeaXLS = Publication.defaultData.fileOperatorIdeaXLS;
    fileConsumerIdeaXLS = Publication.defaultData.fileConsumerIdeaXLS;
    fileSamplePDF = Publication.defaultData.fileSamplePDF;
    files = Publication.defaultData.files;
    id = Publication.defaultData.id;
    title = Publication.defaultData.title;
    description = Publication.defaultData.description;
    bigImageName = Publication.defaultData.bigImageName;
    featuredContentImageName = Publication.defaultData.featuredContentImageName;
    numberOfPages = Publication.defaultData.numberOfPages;
    minutesToRead = Publication.defaultData.minutesToRead;
    createDate = Publication.defaultData.createDate;
    tagsIds = Publication.defaultData.tagsIds;
    buttonCategoryId = Publication.defaultData.buttonCategoryId;
    titleCategoryId = Publication.defaultData.titleCategoryId;
    isPublished = Publication.defaultData.isPublished;
    chapters = Publication.defaultData.chapters;
    price = Publication.defaultData.price;
    subscriptionPackageIds = Publication.defaultData.subscriptionPackageIds;

    static defaultData: iPublication = {
        filePDF: null,
        filePPT: null,
        fileOperatorIdeaXLS: null,
        fileConsumerIdeaXLS: null,
        fileSamplePDF: null,
        files: [],
        id: 0,
        title: "",
        description: "",
        bigImageName: "",
        featuredContentImageName: '',
        numberOfPages: 0,
        minutesToRead: 0,
        createDate: transformDate(new Date().toString()) || "",
        tagsIds: [],
        buttonCategoryId: 0,
        titleCategoryId: 0,
        isPublished: false,
        chapters: [],
        price: null,
        subscriptionPackageIds: [],
    }

    constructor(data?: iPublicationAdmin__API) {
        if (data) {
            this.mapFromApi(data);
        }
    }

    private getFileType = (type: string) => {
        switch (type) {
            case "OperatorIdeaTool":
                return FileTypes.XlsOperatorIdeaTool;
            case "ConsumerIdeaTool":
                return FileTypes.XlsConsumerIdeaTool;
            case "Pdf":
                return FileTypes.Pdf;
            case "Ppt":
                return FileTypes.Ppt;
            case "SamplePdf":
                return FileTypes.SamplePdf;
            default:
                return FileTypes.None;
        }
    }

    private getFiles = (files: iFile[] = []) => {
        const file = (type: FileTypes) => files.find((item) => item.type === type) || null;

        return {
            filePDF: file(FileTypes.Pdf),
            filePPT: file(FileTypes.Ppt),
            fileOperatorIdeaXLS: file(FileTypes.XlsOperatorIdeaTool),
            fileConsumerIdeaXLS: file(FileTypes.XlsConsumerIdeaTool),
            fileSamplePDF: file(FileTypes.SamplePdf),
        };
    };


    private setData(model: iPublication) {
        ({
            id: this.id,
            filePDF: this.filePDF,
            filePPT: this.filePPT,
            fileOperatorIdeaXLS: this.fileOperatorIdeaXLS,
            fileConsumerIdeaXLS: this.fileConsumerIdeaXLS,
            fileSamplePDF: this.fileSamplePDF,
            files: this.files,
            title: this.title,
            description: this.description,
            bigImageName: this.bigImageName,
            featuredContentImageName: this.featuredContentImageName,
            numberOfPages: this.numberOfPages,
            minutesToRead: this.minutesToRead,
            createDate: this.createDate,
            tagsIds: this.tagsIds,
            buttonCategoryId: this.buttonCategoryId,
            titleCategoryId: this.titleCategoryId,
            isPublished: this.isPublished,
            chapters: this.chapters,
            price: this.price,
            subscriptionPackageIds: this.subscriptionPackageIds,
        } = model);
    }

    private mapFromApi(apiModel: iPublicationAdmin__API) {
        const currentFiles: iFile[] = apiModel.files.map(file => ({
            filename: file.filename,
            type: this.getFileType(file.type)
        }));

        const {
            filePDF,
            filePPT,
            fileOperatorIdeaXLS,
            fileConsumerIdeaXLS,
            fileSamplePDF,
        } = this.getFiles(currentFiles);

        this.setData({
            id: apiModel.id,
            files: apiModel.files,
            title: apiModel.title,
            description: apiModel.description,
            bigImageName: apiModel.bigImageName || Publication.defaultData.bigImageName,
            featuredContentImageName: apiModel.featuredContentImageName,
            numberOfPages: apiModel.numberOfPages,
            minutesToRead: apiModel.minutesToRead,
            createDate: apiModel.createDate,
            tagsIds: apiModel.tagsIds,
            buttonCategoryId: apiModel.buttonCategoryId || Publication.defaultData.buttonCategoryId,
            titleCategoryId: apiModel.titleCategoryId || Publication.defaultData.titleCategoryId,
            isPublished: apiModel.isPublished,
            chapters: apiModel.chapters,
            price: apiModel.price || 0,
            subscriptionPackageIds: apiModel.subscriptionPackageIds,
            filePDF,
            filePPT,
            fileOperatorIdeaXLS,
            fileConsumerIdeaXLS,
            fileSamplePDF,
        });
    }
}

export interface iShortPublication {
    id: number;
    title: string;
    createDate: string | null;
    titleCategoryName: string;
}

export class ShortPublication implements iShortPublication {
    id = 0;
    title = "";
    createDate = null;
    titleCategoryName = '-';
}
