import { useCallback, useEffect, useState } from "react";
import useFetch from "use-http";
import { getApiLink } from "../apiConfig";
import { iPublicationShort__API } from "../apiSchema";
import API from "../enums/API";
import { iShortPublication } from "../models/iPublication";

const useGetPublications = () => {
    const { get, response, post, abort, loading = true } = useFetch<iPublicationShort__API[]>();
    const [data, setData] = useState<iShortPublication[]>([]);

    const getPublications = useCallback(async () => {
        const publications = await get(getApiLink(API.GET_SHORT_PUBLICATION));

        if (response.ok && publications) {
            const data = publications.map((el: iPublicationShort__API) => {
                return {
                    ...el,
                    title: el.title ? el.title : ""
                };
            });

            setData(data);
        }
    }, [get, response]);


    useEffect(() => {
        getPublications();
    }, [getPublications]);

    useEffect(() => {
        return () => {
            abort();
        }
    }, [abort]);

    return { data, loading, post, getPublications, response }
}

export default useGetPublications;